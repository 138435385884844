@import "~@andes/list/index";
@import "~@andes/card/index";

.online-payments-container {
  padding: 40px 26px;
  max-width: 960px;
}

.online-payments-main-cards {
  width: 100%;
  display: flex;
  margin: 40px auto 0;
  flex-flow: column nowrap;
}

.online-payments-secondary-cards {
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-flow: column nowrap;
  justify-content: flex-start;

  h3 {
    margin-top: 0 !important;
  }
}

.online-payments-title {
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-flow: column-reverse nowrap;

  .online-payments-title__text {
    width: 100%;
    justify-content: flex-end;

    h1 {
      font-size: 32px;
      font-weight: 600;
      line-height: 1.15;
      color: $black-2;
      margin-top: 65px;
      margin-bottom: 20px;
    }

    h3 {
      margin-top: 40px;
      margin-bottom: 20px;
      overflow: hidden;
      color: $black-2;
      font-weight: 600;
    }
  }
}

.online-payments__card {
  display: flex;
  margin-bottom: 24px;
  max-width: 100%;
  max-height: 280px;
  text-align: left;
  padding: 0 !important;

  h3 {
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    margin: 20px 0 8px;
    color: $black-2;
  }

  p {
    font-size: 14px;
    line-height: 1.29;
    color: $black-2;
    margin-top: 16px;
    margin-bottom: 0;
  }

  .card-content {
    padding: 24px !important;
    overflow: hidden;
  }
}

p {
  text-decoration: none;
  font-weight: 400;
  outline: none !important;
  box-sizing: border-box;
}

.online-payments-main-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  flex-direction: column;

  &--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &--title {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 28px;
    color: rgb(0 0 0 / 80%);
    margin-bottom: 10px;
    text-align: center;
  }

  &--text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: rgb(0 0 0 / 80%);
    margin-bottom: 24px;
    text-align: center;
  }

  &--button {
    width: fit-content;
    margin-bottom: 32px;
  }

  &--card {
    margin-top: 24px;
  }
}
