@import "~@andes/message/index";

.overview-layout-container {
  padding: 65px 0 0 30px;
  margin-right: 240px;
}

.overview-container {
  max-width: 960px;
  padding: 0;
  margin: 0 auto;
}

.overview-title__text {
  width: 100%;
  justify-content: flex-end;

  h1 {
    margin: 64px 48px 8px 67px;
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  h2 {
    margin: 26px 48px 12px 67px;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  p {
    margin: 0 48px 34px 67px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }
}

.overview-text {
  h3 {
    margin: 26px 48px 12px 67px;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  h2 {
    margin: 26px 48px 12px 67px;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  p {
    margin: 0 48px 24px 67px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }
}

.overview-text-note {
  h3 {
    margin: 26px 48px 12px 67px;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  h2 {
    margin: 26px 48px 12px 67px;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  p {
    margin: 10px 48px 20px 67px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }
}

.overview-card-note {
  margin: 24px 48px 48px 67px;

  p {
    margin: 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }
}

.overview-enum {
  h3 {
    margin: 26px 48px 12px 67px;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  h2 {
    margin: 26px 48px 12px 67px;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  p {
    margin: 0 48px 0 67px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  h2 {
    margin: 26px 48px 12px 67px;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  li {
    margin: 0 48px 12px 67px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }
}

.overview-bullet {
  h3 {
    margin: 26px 48px 0 67px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  h2 {
    margin: 26px 48px 12px 67px;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  p {
    margin: 0 48px 30px 67px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  li {
    margin: 0 48px 19px 77px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }
}

.overview-card-container {
  width: 86%;
  flex-direction: row;
  justify-content: space-between;
}

.overview-card {
  margin-bottom: 24px;
  width: calc(50% - 16px);
  text-align: left;
  padding: 0 !important;
}

.overview-card-content {
  width: 100%;

  h3 {
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    margin: 20px 0 8px;
    color: $black-2;
  }

  h2 {
    margin: 26px 48px 12px 67px;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  h2 {
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    margin: 24px 0 8px;
    color: $black-2;
  }

  p {
    font-size: 14px;
    line-height: 1.29;
    color: $black-2;
    margin-top: 16px;
    margin-bottom: 0;
  }

  .card-content {
    padding: 24px !important;
    overflow: hidden;
  }
}

.overview-note {
  width: 744px;
  height: 86px;
  margin: 24px 48px 48px 67px;
  padding: 0 24px 0 0;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 12%);
  background-color: #fff;

  p {
    width: 696px;
    height: 38px;
    margin: 24px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
    padding-top: 24px;
  }
}

.overview-message {
  margin: 0 54px 18px 67px;
  background-color: rgb(0 0 0 / 4%);

  p {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }
}
