
.home-contact {
  height: 234.6px;
  display: flex;
  align-items: center;

  .home-contact__input {
    flex-direction: row;
  }

  .home-contact__email {
    width: 384px;
  }

  .home-contact__content {
    max-width: 866px;
    margin: 0 auto;
  }

  .home-contact__button {
    width: 156px;
    margin-top: 13px;
    margin-right: 23px;
    margin-left: 15px;
    font-size: 18px;
  }

  .home-contact__text {
    padding-bottom: 0;
  }
}

.contact-subtitle {
  padding: 8px 0 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(0 0 0 / 45%);
  font-family: "Proxima Nova";
}

.contact-title {
  padding: 0;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(0 0 0 / 80%);
  font-family: "Proxima Nova";
}

.contact-title-error {
  padding-bottom: 0;
}
