.step-cards-container {
  display: flex;
  max-width: 50.875em;
  margin: 0 auto;
}

.next-step-card-container {
  display: flex;
  justify-content: start;
  margin-left: 16px;
}

.next-step-card-title-container {
  padding-left: 10px;
}

.next-step-card-chevron {
  margin-top: 31px;

  svg {
    path {
      fill: $andes-accent-color;
    }
  }
}

.next-step-card-chevron-prev {
  position: absolute;
  margin-left: -19px;
  margin-top: 31px;
  margin-right: 18px;

  svg {
    path {
      fill: $andes-accent-color;
    }
  }
}

.next-step-title {
  color: $andes-accent-color;
  font-size: 16px;
  padding-top: 10px;
  padding-right: 19px;

  &:hover {
    color: var(--andes-color-blue-600)
  }
}

.next-step-card-text {
  font-size: 14px;
  color: $black-7;
  padding-right: 24px;
  display: none;
}

.next-step-card-title {
  font-size: 14px;
  color: $black-7;
}

.next-step-card-container:hover .next-step-title {
  color: var(--andes-color-blue-600);
}

.step-cards-container__divisor {
  display: none;
}
