//Use anchored menu styles

.anchored-menu-landing {
    display: block;
    top: 7px;
    left: 0;
    bottom: 7px;
    padding-left: 16px;

    &-a {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
}  
