.ftu-modal {
  padding: 32px;
  width: 480px;
  height: 460px;
}

.ftu-modal {
  padding: 24px;

  .andes-modal-dialog__content {
    padding: 0;
  }
}

.ftu-modal__text {
  margin-top: 30px;
}

.ftu-modal__p {
  margin-top: 12px;
}

.ftu-modal__button-container {
  margin-top: 36px;
}

.ftu-modal-component {
  max-height: 32em !important;

  .andes-modal-dialog__content {
    padding: 3em !important;
  }
}
