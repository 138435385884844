@import "~@andes/dropdown/index";
@import "~@andes/form/index";
@import "~@andes/list/index";
@import "~@andes/card/index";
@import "~@andes/button/index";
@import "~@andes/textfield/index";
@import "~@andes/radio-button/index";
@import "~@andes/tooltip/index";
@import "~@andes/message/index";
@import "../../ContactDeveloper/styles.mobile";
@import "./components/HomeBanner/styles.mobile";
@import "~@andes/progress-indicator-circular/index";

.home-container {
  padding-top: 65px;
}

.home-title {
  width: 100%;
  max-width: 866px;
  display: flex;
  margin: 48px auto 40px;
  flex-flow: column-reverse nowrap;

  .home-title__text {
    width: 85%;
    justify-content: flex-end;
    padding-left: 25px;
    padding-right: 25px;

    h1 {
      font-size: 36px;
      font-weight: 700;
      line-height: 1.15;
      color: $black-2;
      margin-top: 65px;
      margin-bottom: 20px;
    }
  }

  .home-title__image {
    width: 44%;
    margin-right: 26px;
    justify-content: flex-start;
    text-align: right;
    padding-right: 8px;
    padding-left: 20px;
  }
}

.home-fast-access {
  display: flex;
  justify-content: space-between;
  max-width: 866px;
  margin: 40px 10px;
  padding-right: 16px;
  padding-left: 16px;
  flex-direction: column;

  .andes-card {
    padding: 0 !important;
  }

  .home-fast-access__card {
    margin-bottom: 24px;
    width: 100%;
    max-height: 280px;
    text-align: center;
    padding: 0 !important;

    h3 {
      font-size: 20px;
      font-weight: 600;
      color: $black-2;
      margin: 0 0 18px;
    }

    p {
      font-size: 14px;
      line-height: 1.29;
      color: $black-2;
    }

    .card-content {
      padding: 20px 40px 32px;
      overflow: hidden;
    }

    svg {
      vertical-align: middle;
      margin: 12px 0;
    }
  }
}

.home-banner {
  margin-top: 0;
  margin-bottom: 0;
  background-color: $light-gray-4;
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .home-banner-card {
    margin: "40px 16px 40px 16px";
    padding: 34px 16px;

    &:nth-child(1) {
      background: $dark-blue-4;
    }

    &:nth-child(2) {
      background: $andes-accent-color;
    }

    &__title,
    &__subtitle {
      color: $white;
      word-break: normal;
    }

    &__title {
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
    }

    &__subtitle {
      font-size: 16px;
    }

    &__button {
      background: $black-4;
    }

    &__image {
      display: none;
    }
  }

  .home-banner__content {
    margin: 0 auto;
    padding: 0 10px;
    width: 100%;
    max-width: 866px;
  }

  .home-banner__image {
    display: block;
    margin-top: 40px;

    // width: 40%;
    max-height: 192px;

    // margin-left: 26px;
    height: auto;
    text-align: right;
  }

  .home-banner__texts {
    width: 97%;
    height: auto;
    margin-right: 26px;
    padding: 24px;

    h3 {
      font-size: 10px;
      font-weight: 600;
      line-height: 1.06;
      letter-spacing: 0.5px;
      color: $white;
      margin-bottom: 12px;
      margin-top: 24px;
      height: auto;
      overflow: hidden;
    }

    h2 {
      font-size: 20px;
      max-width: 360px;
      font-weight: 700;
      line-height: 1.2;
      color: $white;
      margin-top: 0;
      margin-bottom: 24px;
      font-family: "Proxima Nova";
    }

    .home-banner__button--desktop {
      background-color: $dark-blue-4;
    }

    .home-banner__button--mobile {
      font-size: 16px;
      margin-top: 24px;
      font-weight: 600;
      line-height: 1;
      color: $white;
    }

    .andes-button:hover {
      background-color: $dark-blue-5;
    }

    .andes-button--small {
      margin-top: 0;
    }
  }
}

.home-footer {
  max-width: 816px;
  margin: 0 auto 48px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 25px;
  padding-right: 25px;

  h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    color: $black-2;
    max-width: 200px;
    overflow: hidden;
    margin: 14px 0;
  }

  a {
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    color: $andes-accent-color;
  }

  .home-footer__card {
    max-width: 179px;

    &--first {
      padding-top: 31px;
      padding-bottom: 32px;
    }

    &--middle {
      padding-top: 31px;
      padding-bottom: 32px;
      border: 1px solid $light-gray-6;
      border-left: none;
      border-right: none;
    }

    &--last {
      padding-top: 31px;
      padding-bottom: 32px;
    }
  }
}
