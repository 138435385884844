.home-static-banner {
  background: linear-gradient(244.17deg, $light-blue-4 0%, $dark-blue-6 100%);
  width: 100%;
  display: flex;
  margin: 0;
  flex-wrap: nowrap;
}

.home-static-banner-content {
  max-width: 100%;
  margin: 40px auto;
  padding: 0 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .home-static-banner-text {
    font-family: "Proxima Nova";
    font-style: normal;
    max-width: 510px;
  }

  .home-static-banner-text__title {
    text-align: center;

    h1 {
      font-weight: 800;
      font-size: 40px;
      line-height: 40px;
      text-transform: uppercase;
      color: $white;
      margin-bottom: 0;
    }
  }

  .home-static-banner-text__detail {
    text-align: center;

    p {
      margin-top: 0;
      padding-top: 16px;
      font-weight: 300;
      font-size: 24px;
      line-height: 24px;
      color: $white;
    }
  }

  .home-static-banner-image {
    padding-top: 16px;
  }

  .home-static-banner-button {
    padding-top: 24px;

    .andes-button--loud,
    .andes-button--loud:link,
    .andes-button--loud:visited,
    .andes-button--loud:focus {
      background-color: $white;
      color: $andes-accent-color;
      width: 100%;
    }
  }
}
