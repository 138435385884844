@import "~@andes/card/index";
@import "~@andes/button/index";
@import "~@andes/textfield/index";
@import "~@andes/radio-button/index";
@import "~@andes/tooltip/index";
@import "~@andes/message/index";

.home-contact {
  width: 100%;
  background: $white;
  text-align: center;
  height: 316.6px;
  display: flex;
  align-items: center;

  .home-contact__text {
    padding-bottom: 25px;
  }

  .home-contact__content {
    max-width: 866px;
    margin: 0 auto;
    padding: 48px 16px;
  }

  .home-contact__input {
    display: flex;
    justify-content: center;
    flex-direction: column;
    vertical-align: middle;
    caret-color: #009ee3;
  }

  .home-contact__email {
    max-width: none;
  }

  .home-contact__button {
    cursor: pointer;
    height: 48px;
    border-radius: 4px;
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
  }

  h2 {
    font-size: 20px;
  }

  .andes-form-control--textbox {
    padding-top: 9px !important;
  }
}

.contact-subtitle {
  padding-top: 8px;
  height: 34px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(0 0 0 / 45%);
  font-family: "Proxima Nova";
}

.contact-subtitle-error {
  padding-top: 24px;
  height: 34px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(0 0 0 / 45%);
  font-family: "Proxima Nova";
}

.contact-title {
  height: 24px;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(0 0 0 / 80%);
  font-family: "Proxima Nova";
}

.contact-title-error {
  padding-bottom: 36px;
  height: 24px;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(0 0 0 / 80%);
  font-family: "Proxima Nova";
}

.contact-login {
  text-decoration: none;
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #009ee3;
}

.contact-icon {
  padding-bottom: 16px;
}
