$andes-theme: mercadopago;
$assets-path: "https://http2.mlstatic.com/frontend-assets/dx-devsite/";
$transparent: rgb(0 0 0 / 0%);
$black: #000;
$black-2: rgb(0 0 0 / 80%);
$black-3: rgb(0 0 0 / 15%);
$black-4: rgb(0 0 0 / 20%);
$black-5: rgb(0 0 0 / 10%);
$black-6: rgb(0 0 0 / 45%);
$black-7: rgb(0 0 0 / 55%);
$black-8: rgb(0 0 0 / 90%);
$black-9: rgb(0 0 0 / 50%);
$black-10: rgb(0 0 0 / 25%);
$white: #fff;
$white-2: #f5f6f8;
$white-3: rgb(255 255 255 / 40%);
$white-4: #ebebeb;
$white-5: #f9f9f9;
$white-6: #ededed;
$white-7: #fcfcfc;
$pale-gray: #eff0f3;
$mp-light-blue: var(--andes-color-blue-500);
$light-gray-1: #f2f2f2;
$light-gray-2: #f5f6f8;
$light-gray-3: #dbe1ec;
$light-gray-4: #eee;
$light-gray-5: #d6d6d6;
$light-gray-6: #e2e2e4;
$light-gray-7: #999;
$light-gray-8: #f5f5f5;
$light-gray-9: #eaeaea;
$light-gray-10: #e5e5e5;
$light-gray-11: #d9d9d9;
$light-gray-12: rgba(245, 245, 245, 0.3);
$light-gray-14: rgba(237, 237, 237, 1);
$dark-gray-1: #637080;
$dark-gray-2: #dae1ec;
$dark-gray-3: #4d5763;
$dark-gray-4: #323232;
$dark-gray-5: #666;
$dark-gray-6: #444;
$dark-gray-7: #333;
$dark-gray-8: #bfbfbf;
$dark-gray-9: #989898;
$dark-gray-10: #828282;
$dark-gray-11: #7d7d7d;
$dark-gray-12: rgba(245, 245, 245, 0.50);
$dark-gray-14: rgba(245, 245, 245, 0.25);
$red: #f00;
$red-2: #f23d4f;
$gray: #808080;
$blue: #00f;
$blue-1: rgb(71 154 209 / 10%);
$blue-2: rgb(71 154 209 / 10%);
$dark-blue: #283480;
$dark-blue-2: #282e57;
$dark-blue-3: #04204c;
$dark-blue-4: #007eb5;
$dark-blue-5: #005e89;
$dark-blue-6: #7894bd;
$dark-blue-7: #03117f;
$dark-blue-8: #0b105b;
$dark-blue-9: #2445b8;
$dark-blue-10: #455161;
$light-blue-1: #d7e6ef;
$light-blue-2: #5ec2ff;
$light-blue-3: #ebf2f8;
$light-blue-4: #00b1ea;
$light-blue-5: #068ee4;
$light-blue-6: #2968c8;
$light-blue-7: #009ee3;
$light-blue-8: #32b3ff;
$light-blue-9: #3483fa;
$light-blue-10: #479AD126;
$light-blue-11: #00bcff;
$light-gray: #eee;
$green: #00a650;
$green-2: #689f63;
$green-3: rgb(0 166 80 / 10%);
$green-4: #4e8d2e;
$orange: #f73;
$orange-1: #f73;
$orange-2: #e76f00;
$orange-3: rgb(255 119 51 / 10%);
$orange-4: #c27a35;
$violet: #785eb3;
$violet-2: #6e2d93;
