.online-payments-container {
  padding: 65px 26px 40px;
  margin: 0 auto;
}

.online-payments-main-cards {
  flex-direction: row;
  justify-content: space-between;
}

.online-payments-secondary-cards {
  flex-direction: row;
  justify-content: start;
}

.online-payments__card {
  margin-bottom: 24px;
  width: calc(33.3% - 16px);
  max-height: 280px;
  text-align: left;
  padding: 0 !important;
  margin-right: 16px;
}

.online-payments-title {
  flex-direction: row;
}

.online-payments-main-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 42px;
  flex-direction: row;

  &--content {
    align-items: unset;
  }

  &--title {
    text-align: unset;
  }

  &--text {
    margin-bottom: 28px;
    text-align: unset;
  }

  &--button {
    width: fit-content;
    margin-bottom: unset;
  }

  &--card {
    margin-top: 60px;
  }
}
