@import "../../ContactDeveloper/styles.desktop";
@import "./components/HomeBanner/styles.desktop";
@import "~@andes/progress-indicator-circular/index";

.home-container {
  padding-top: 65px;
}

.home-title {
  flex-direction: row;

  .home-title__text {
    width: 48%;
    justify-content: flex-start;
    margin-left: 26px;
    padding-left: 0;
    padding-right: 0;
  }

  .home-title__image {
    width: 44%;
    justify-content: flex-end;
    text-align: center;
    padding-right: 0;
    padding-left: 8px;
    margin-right: 0;
  }
}

.home-fast-access {
  flex-direction: row;
  margin: 40px auto 20px;
  padding-right: 0;
  padding-left: 0;

  .home-fast-access__card {
    margin-left: 26px;
    width: 33%;
  }
}

.home-banner {
  margin-bottom: 48px;

  &__content {
    display: flex;
    flex-direction: row;
    max-width: 100% !important;
    width: auto !important;
  }

  .home-banner-card {
    padding: 0 24px 0 0;
    width: 471px;

    &__title {
      font-weight: 600;
    }

    &__content {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__image {
      display: flex;
      margin-right: 16px;
    }

    &__text-container {
      margin: 45px 0;
    }
  }
}

.home-footer {
  flex-direction: row;
  padding: 0;

  .home-footer__card--first {
    margin-left: 26px;
    padding: 8px 30px 8px 45px;
  }

  .home-footer__card--middle {
    padding: 8px 30px 8px 45px;
    border: 1px solid $light-gray-6;
    border-top: none;
    border-bottom: none;
  }

  .home-footer__card--last {
    margin-right: 26px;
    padding: 8px 30px 8px 45px;
  }
}
