@import "~@andes/list/index";
@import "~@andes/card/index";

.instore-payments-container {
  padding: 40px 26px;
  max-width: 960px;
  margin: 0 auto;
}

.instore-payments-main-cards {
  width: 100%;
  display: flex;
  margin: 40px auto 0;
  flex-flow: column nowrap;
}

.instore-payments-secondary-cards {
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-flow: column nowrap;
  justify-content: flex-start;
}

.instore-payments-title {
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-flow: column-reverse nowrap;

  .instore-payments-title__text {
    width: 85%;
    justify-content: flex-end;

    h1 {
      font-size: 32px;
      font-weight: 600;
      line-height: 1.15;
      color: $black-2;
      margin-top: 65px;
      margin-bottom: 20px;
    }

    h3 {
      margin-top: 40px;
      margin-bottom: 20px;
      overflow: hidden;
    }
  }
}

.instore-payments__card {
  display: flex;
  margin-bottom: 24px;
  max-width: 100%;
  max-height: 280px;
  text-align: left;
  padding: 0 !important;

  h3 {
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    margin-top: 16px;
    color: $black-2;
  }

  p {
    font-size: 14px;
    line-height: 1.29;
    color: $black-2;
    margin-top: 16px;
    margin-bottom: 0;
  }

  .card-content {
    padding: 24px !important;
    overflow: hidden;
  }
}

p {
  text-decoration: none;
  font-weight: 400;
  outline: none !important;
  box-sizing: border-box;
}
