.sdk-version {
  height: 56px;
  display: flex;
  align-items: center;

  &__icon span {
    display: flex;
  }
}

.sdk-version__last-number {
  font-weight: 600;
  color: rgb(0 0 0 / 80%);
  margin-left: 8px;
}

.sdk-version__last {
  margin-left: 8px;
}
