@import '~dx-virtual-assistent-sdk/lib/ui/styles.css';

.search-ai-holder[data-layout=fixed] .search-dropdown {
  top: 130px;

  @media screen and (min-width: 767px) {
    left: calc(50% + (270px/2));
  }
}

.search-ai-holder .search-input-container {
  form {
    z-index: 1000;
  }
  svg {
    z-index: 1001;
  }
}

.search-ai-holder[data-transported=true] {
  @media screen and (max-width: 767px) {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
  }
}

.dx-search-bar-ai {
  @media screen and (max-width: 767px) {
    padding-left: 4px;
    padding-right: 4px;    
  }
}
