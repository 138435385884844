.sdks-backend-container {
  position: relative;

  .anchored-menu {
    position: fixed;
    top: 72px;
    bottom: 0;
    right: 24px;
  }
}
