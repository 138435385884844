.no-overflow {
  overflow: hidden !important;
}

.tab__sel {
  padding: 2px 24px !important;
}

.bold {
  font-weight: 600;
}

.sdks-content-notification-deprecated {
  margin-top: 16px;

  &__title {
    display: flex;
    align-items: center;

    .sdks-v1-deprecated__title {
      margin: 0 0 0 16px;
    }
  }
}

.overview-link {
  cursor: pointer;
}
