.sdk-version {
  height: 56px;
  display: flex;
  align-items: center;
}

.sdk-version__icon {
  display: flex;
  align-items: center;
}

.sdk-version__last {
  margin-left: 8px;
  color: rgb(0 0 0 / 80%);
}
