.instore-payments-container {
  padding: 65px 26px 40px;
}

.instore-payments-main-cards {
  flex-direction: row;
  justify-content: space-between;
}

.instore-payments-secondary-cards {
  flex-direction: row;
  justify-content: space-between;
}

.instore-payments__card {
  margin-bottom: 24px;
  width: calc(33.3% - 16px);
  max-height: 280px;
  text-align: left;
  padding: 0 !important;
}

.instore-payments-title {
  flex-direction: row;

  .instore-payments-title__text {
    width: 100%;
  }
}
