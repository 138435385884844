@import "../Sidebar/styles.desktop";

.layout {
  width: 100%;
  height: auto;
  background-color: $light-gray-1;
  position: absolute;
  z-index: 0;
}

.sidebar-padding {
  padding-left: 0;
}

.layout-container {
  padding: 0;
  margin-top: 0;
}

.navbar-margin-congrats {
  margin-top: 64px;
}
