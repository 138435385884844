@import "~@andes/tabs/index";
@import "~@andes/dropdown/index";
@import "~@andes/list/index";
@import "~@andes/form/index";
@import "~@andes/badge/index";
@import "./components/Backend/styles.mobile";
@import "./components/Frontend/styles.mobile";
@import "./components/Requirements/styles.mobile";
@import "./components/SdksCard/styles.mobile";
@import "./components/LastVersion/styles.mobile";

.sdks-container {
  margin: 0 auto;
  padding: 2em 1.5em 20px;

  .andes-tab-content {
    padding: 0 !important;
  }
}

.sdks__title {
  display: flex;
  align-items: baseline;
  flex-direction: inherit;
}

.sdks-h2 {
  line-height: 1.17;
  margin: 1.33333em 0;
}

.sdks-title {
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-flow: column nowrap;
}

.sdks-title-ul {
  color: rgb(0 0 0 / 80%);
  margin-left: 32px;
}

.sdk-subtitle {
  font-size: 24px;
  line-height: 1.17;
  margin: 0;
  color: rgb(0 0 0 / 80%);
}

.sdks-content {
  border-bottom: solid 0.5px rgb(0 0 0 / 10%);
  padding: 0 0 24px;

  &__last {
    margin-top: 32px;
  }
}

.landings-h1 {
  margin-top: 65px;
}

.next-step {
  margin-top: 2.33333em;
}
