@import "../../styles/variables";

.sidebar-mobile-navbar {
  position: fixed;
  z-index: 1032;
  top: 65px;
  left: 0;
  display: flex;
  width: 100%;
  height: 58px;
  align-items: center;
  padding: 16px;
  background: $white;
  box-shadow: 0 7px 8px -4px rgb(40 52 128 / 10%);
  border-top: 1px solid #e5e5e5;

  &__title {
    width: 100%;
    display: flex;
    align-items: center;

    p {
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .collapse-image {
    display: flex;
    align-items: center;
    margin-left: 17.85px;
    margin-right: auto;

    &--collapsed {
      transform: rotateX(180deg);
    }
  }

  .sidebar-mobile-navbar__searchbar {
    position: fixed;
    z-index: 80;
    display: block;
    top: 65px;
    left: 0;
    background: #fff;
    height: 58px;
    width: 100%;

    input {
      padding-left: 48px;
      height: 100%;
      width: 100%;
      font-size: 14px;
      line-height: 18px;
      border: 1px solid #e5e5e5;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      box-shadow: 0 4px 8px rgb(40 52 128 / 10%);
      &::placeholder {
        font-weight: 400;
        color: rgb(0 0 0 / 25%);
      }
    }


    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;

      path {
        fill: rgb(0 0 0 / 25%);
      }
    }

    .close-icon-container {
      svg {
        position: absolute;
        right: 20.94px;
        bottom: 21.15px;
        left: auto;

        path {
          fill: #009ee3;
        }
      }
    }
  }
}

.sidebar-container {
  display: block;
  width: 100%;
  background-color: $white;
  height: 100%;
  position: fixed;
  z-index: 1030;
  padding-top: 60px;
  top: 0;
  left: 0;
  overflow: scroll;
}

.nav-container {
  width: 100%;
  background-color: $white;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.ul-container {
  padding-left: 0;
  width: 100%;
  height: auto;
  margin-bottom: 32px;
  margin-top: 67px;

  a {
    font-weight: 300;
  }

  .lvl-0 {
    .lvl-1:last-of-type {
      margin-bottom: 16px;
    }
  }
}

.childs {
  overflow: hidden;
  transition: max-height 0.2s linear;
  max-height: 0;

  @for $i from 1 through 100 {
    &.child-count-#{$i} {
      max-height: 1000px;
    }

    &.child-count-#{$i}-api {
      max-height: 1000px;
    }
  }
}

.ul-container--hidde {
  padding-left: 0;
  animation: fade-out-left 0.2s ease-out forwards;
}

.item-divisor {
  margin: 24px 0;
  display: flex;
  align-items: center;

  .divisor {
    background-color: #e5e5e5;
    height: 1px;
    width: 100%;
  }
}

.item-container {
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  height: 46px;
  align-items: center;

  &.item-container-lvl-1 {
    height: 36px;
    padding-left: 40px;
  }

  &.item-container-lvl-2 {
    height: 36px;
    padding-left: 56px;

    // Remove left marker of selected lvl-1 item if a lvl-2 is selected
    &.item-container--active {
      @at-root .item-container-lvl-1.item-container .left-marker {
        display: none;
      }
    }
  }

  &.api-ref-container {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
  }

  .image,
  .image svg {
    width: 24px;
    height: 24px;
  }

  .title-lvl-0 {
    padding-right: 8px;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    color: rgba($black, 0.9);
    margin-bottom: 0;
    font-family: "Proxima Nova";
  }

  .title-lvl-1 {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: rgba($black, 0.55);
    font-family: "Proxima Nova";
  }

  .title-lvl-2 {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: rgba($black, 0.55);
    font-family: "Proxima Nova";
  }

  .subgroup-title {
    font-stretch: normal;
    font-family: "Proxima Nova";
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: normal;
  }

  &.api-ref-container {
    &.item-container--active .title-lvl-0 {
      color: $andes-accent-color !important;
    }

    &.item-container-lvl-0 {
      padding-left: 40px;
    }

    &.item-container-lvl-0 .title {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: rgba($black, 0.55);
      font-family: "Proxima Nova";
    }

    .title-lvl--1 {
      padding-right: 8px;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 18px;
      letter-spacing: normal;
      color: rgba($black, 0.9);
      margin-bottom: 0;
      font-family: "Proxima Nova";
    }
  }

  .collapse-image {
    margin-left: auto;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 !important;
  }

  .collapse-image--collapsed {
    margin-left: auto;
    padding: 0 !important;
    transform: rotateX(180deg);
  }
}

.link-new-page {
  .external-link-image {
    display: none;
    margin-left: 8px;
  }

  &:hover {
    .external-link-image {
      display: block;
    }
  }
}

.slideout-menu {
  transform: translateY(-100%);
  transition: transform 0.35s ease-in-out;

  &--open {
    transform: translateY(0);
  }
}

.slideout-panel {
  overflow-y: scroll;
  max-height: 100vh;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-dimmer {
  visibility: visible;
  opacity: 1;
  z-index: 70;
}

.dev-main__overlay {
  position: fixed;
  inset: 0;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  background-color: rgba($andes-black, 0.5);
  pointer-events: none;
}

.next-level-container {
  display: block;
  width: 270px;

  &.next-level-container--invisble {
    display: none;
  }

  .item-back-label {
    display: flex;
    height: 46px;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 16px;

    span {
      margin-left: 8px;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: rgba($black, 0.45);
    }
  }

  .item-actual-title {
    display: block;
    height: 46px;
    line-height: 46px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $black;
    padding-left: 24px;
    margin-top: 16px;
  }
}

.left-marker {
  display: none;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-in-right {
  0% {
    opacity: 0;
    margin-left: 270px;
  }

  100% {
    opacity: 1;
    margin-left: 0;
  }
}

@keyframes fade-out-right {
  0% {
    opacity: 1;
    margin-left: 0;
  }

  100% {
    opacity: 0;
    margin-left: 270px;
  }
}

@keyframes fade-in-left {
  0% {
    margin-left: -270px;
  }

  100% {
    margin-left: 0;
    display: block;
  }
}

@keyframes fade-out-left {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -270px;
  }
}

.fade-in-right {
  animation: fade-in-right 0.35s ease-out forwards;
}

.fade-out-right {
  animation: fade-out-right 0.35s ease-out forwards;
}

.fade-in-left {
  animation: fade-in-left 0.35s ease-out forwards;
}

.fade-out-left {
  animation: fade-out-left 0.35s ease-out forwards;
}

.fade-out {
  animation: fade-out 0.2s ease-out forwards;
}

.fade-in {
  animation: fade-in 0.2s ease-out forwards;
}

.inner-content--empty {
  position: fixed;
  inset: 50px 0 0;
  height: 100vh;
}

.item-container--active {
  position: relative;

  .left-marker {
    width: 4px;
    height: 18px;
    margin-right: 20px;
    border-radius: 0 4px 4px 0;
    background-color: $andes-accent-color;
    display: block !important;
    position: absolute;
    left: 0;
  }

  .title {
    width: 100%;
    font-weight: 600;
    color: $andes-accent-color !important;
  }

  .collapse-image {
    margin-left: auto;
    display: flex;
    align-items: center;
    transform: rotateX(180deg);
    padding: 16px 0 0;

    svg path {
      fill: $andes-accent-color;
    }
  }

  .collapse-image--closed {
    transform: rotateX(0deg) !important;
  }
}

.isnew-dot {
  width: 8px;
  min-width: 8px;
  border-radius: 4px;
  margin-right: 8px;
  height: 8px;
  min-height: 8px;
  background-color: $andes-accent-color;
}

.title-divisor-above {
  pointer-events: none;
  cursor: default;
}

.api-ref-pill {
  min-width: 50px;

  p {
    font-weight: 600 !important;
  }
}

p.title {
  &.bold {
    font-weight: 600 !important;
  }

  &.title-divisor-above.subgroup-title {
    font-size: 14px;
    color: rgba($black, 0.25);
    font-weight: 600;

    & + .collapse-image {
      padding: 8px 0;
    }
  }
}

.api-group-title {
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  color: $dark-gray-4;
  opacity: 0.8;
  white-space: nowrap;
  padding: 18px 24px 10px;
}

.search-control-divisor {
  color: #ededed;
  height: 26px;
  border-right: 1px solid #ededed;
  align-self: center;
  display: block;
  margin-right: 16.2px;
}

// .spinner_test {
//   .andes-spinner__mask {
//     top: 123px;
//   }
// }

.sidebar-desktop-navbar {
  display: none;
}
