.plugins-container {
  padding: 65px 26px 40px;
  margin: 0 auto;
}

.plugins-main-card {
  margin: 48px auto 24px;

  .plugins-main-card__text {
    padding: 38px 0 38px 72px;
    width: 60%;

    h1 {
      font-size: 32px;
    }
  }

  .plugins-main-card__image {
    margin-right: 70px;
  }

  .plugins-main-card-container {
    flex-direction: row;
  }
}

.plugins-title {
  h2 {
    margin: 24px 0 32px;
  }
}

.plugins-secondary-cards {
  flex-flow: row wrap;
  justify-content: space-between;
}

.plugins__card {
  margin-bottom: 24px;
  max-width: 468px;
  max-height: 280px;
  text-align: left;
  padding: 0 !important;
  width: calc(50% - 12px) !important;
}

// Custom

.landings-h1 {
  line-height: 1.15;
  margin-top: 65px;
  margin-bottom: 20px;
}
