.sdk-requirements,
.sdk-caracteristicas {
  margin-top: 36px;

  &__title {
    font-size: 20px;
    line-height: 1.05;
    color: rgb(0 0 0 / 80%);
  }
}
