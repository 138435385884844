@import "../Sidebar/styles.mobile";

.layout {
  width: 100%;
  height: auto;
  background-color: $light-gray-1;
  position: absolute;
  z-index: 0;
}

.layout-container {
  padding: 0;
  margin-top: 0;
}

.main-page-container {
  padding-top: 0;
  min-height: 100vh;
}
