.sdk-card {
  margin-top: 48px;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 12%);
  background-color: #fff;

  &__item {
    display: flex;
    height: 80px;
    align-items: center;
    border-bottom: solid 1px rgb(0 0 0 / 10%);

    &:hover {
      cursor: pointer;
      background-color: rgb(0 0 0 / 4%);
    }

    &:hover:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &:hover:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    &:focus {
      color: rgb(0 0 0 / 80%);
      text-decoration: none;
    }
  }

  &__item-title {
    color: rgb(0 0 0 / 80%);
    width: 100%;
  }

  &__item-title:focus {
    color: rgb(0 0 0 / 80%);
  }

  &__item:last-child {
    border-bottom: none;
  }

  &__item-icon {
    padding-left: 32px;
    padding-right: 18px;
  }

  &__item-chevron {
    margin-right: 26px;
  }
}
