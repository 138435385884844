@import "~@andes/list/index";
@import "~@andes/card/index";

.plugins-container {
  padding: 40px 16px;
  width: 100%;
  max-width: 960px;

  // margin: 0 16px;
}

h1,
h2,
h3,
p {
  color: $black-2;
}

h1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.15;
  margin-top: 65px;
  margin-bottom: 20px;
}

h2 {
  font-weight: 600;
}

p {
  font-weight: 400;
  margin-bottom: 0;
  text-decoration: none;
  outline: none !important;
  box-sizing: border-box;
}

.plugins-title {
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-flow: column nowrap;

  h1 {
    font-weight: 700;
  }

  h2 {
    margin-top: 32px;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.plugins-main-card {
  width: 100%;
  margin: 36px auto 12px;

  .card-content {
    padding: 0 !important;
  }

  .plugins-main-card-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .plugins-main-card__text {
    padding: 24px 24px 43px;
    width: 100%;

    p {
      margin: 0;
    }

    h1 {
      padding-bottom: 44px;
      padding-top: 0;
      margin-top: 0;
      font-size: 24px;
      line-height: 1.25;
      border-radius: 6px;
      background-color: $white;
      margin: 0;
    }
  }

  .plugins-main-card__image {
    padding: 26px 0 0 15px;
    display: flex;
    align-items: center;

    img {
      max-width: 229px;
      max-height: 247px;
      width: auto;
      height: auto;
    }
  }

  .plugins-main-card__image.end {
    align-items: flex-end !important;
  }

  .plugins-main-card__button {
    padding: 0 38px;
    font-size: 0.875em;
    line-height: 2.7;
    background: $andes-accent-color;
    border-radius: 4px;
    border: 0;
    text-align: center;
    width: 210px;
    height: 37px;
  }
}

.plugins-secondary-cards {
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-flow: column nowrap;
  justify-content: flex-start;

  .plugins__card {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 24px;
    max-height: 280px;
    text-align: left;
    padding: 0 !important;

    h3 {
      font-size: 16px;
      text-decoration: none;
      font-weight: 600;
      margin: 20px 0 8px;
    }

    p {
      font-size: 14px;
      line-height: 1.29;
    }

    .card-content {
      padding: 24px 16px !important;
      overflow: hidden;
      display: flex;
      flex-direction: row;

      .plugins-secondary-card__image {
        display: flex;
        margin-right: 12px;
        align-items: center;
        vertical-align: middle;
        min-width: 56px;
      }

      .plugins-secondary-card__text {
        flex-direction: column;
        vertical-align: top;

        p {
          margin-top: 8px !important;
          margin-bottom: 0;
        }

        h3 {
          margin-top: 0 !important;
        }
      }
    }
  }
}
