@import "~@andes/list/index";
@import "~@andes/card/index";
@import "~@andes/message/index";

.overview-container {
  padding: 5em 1.5em 20px;

  .right-sidebar {
    padding-top: 0 !important;
    
    .feedback-thumbs {
      margin-bottom: 24px;
    }
  }

  .feedback {
    margin-top: 0 !important;
    margin-bottom: 24px !important;
  }

  .anchored-menu {
    margin-top: 0 !important;
  }
}

.overview-card-content {
  width: 312px;

  h3 {
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    margin: 20px 0 8px;
    color: $black-2;
  }

  h2 {
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    margin: 20px 0 8px;
    color: $black-2;
  }

  p {
    font-size: 14px;
    line-height: 1.29;
    color: $black-2;
    margin-top: 12px;
    margin-bottom: 0;
  }
}

.overview-card-container {
  width: 100%;
  display: flex;
  margin: 40px auto 0;
  flex-flow: column nowrap;
}

.overview-card {
  display: flex;
  margin-bottom: 24px;
  max-width: 100%;
  text-align: left;
  padding: 0 !important;
}

.overview-title__text {
  width: 100%;
  justify-content: flex-end;

  h1 {
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  h2 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }
}

.overview-enum {
  h3 {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  h2 {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  li {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }
}

.overview-text {
  h3 {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  h2 {
    padding-top: 15px;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  p {
    margin-top: 12px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }
}

.overview-card-note {
  margin: 24px 0;

  p {
    margin: 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }
}

.overview-bullet {
  h3 {
    margin: 15px 0 0;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  p {
    margin: 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }

  li {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }
}

.overview-message {
  p {
    margin-top: 0;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
  }
}
