/* eslint-disable max-ids */
// sass-lint:disable-all

/* Animations
---------------------------------- */

/* Transform
---------------------------------- */

/* Transition
---------------------------------- */

/* px to em
---------------------------------- */
*,
::before,
::after {
  box-sizing: border-box;
}

@import "~@andes/breadcrumb/index";
@import "~@andes/dropdown/index";
@import "~@andes/textfield/index";
@import "~@andes/progress-indicator-circular/index";

html {
  text-size-adjust: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgb(0 0 0 / 10%);
}

p {
  margin-top: 0;
  font-weight: 400;
}

button,
label {
  cursor: pointer;
}

body {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: $black-2;
  background: #fff;
}

body.block-scroll,
.slideout-open body {
  overflow: hidden;
}

p {
  font-size: 0.875em;
}

a {
  color: $andes-accent-color;
  text-decoration: none;
}

a:focus {
  outline: 0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: $andes-blue-600;
  text-decoration: none !important;
}

a:active {
  color: $andes-blue-600;
  text-decoration: none;
}

input,
select {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

img {
  max-width: 100%;
}

p {
  font-size: 1em;
  margin: 1em 0;
  color: $black-2;
}

strong,
b {
  font-weight: 600;
}

h2 + h3,
h1 + h2 {
  margin-top: 0;
}

h1 + blockquote {
  margin-top: 2.85714em;
}


.u-hide {
  display: none !important;
}

.u-show {
  display: block !important;
}

.u-show-ib {
  display: inline-block !important;
}

.u-visibility-hidden {
  position: absolute;
  visibility: hidden;
  width: 0;
  height: 0;
}

.u-display-b {
  display: block;
}

.u-display-ib {
  display: inline-block;
}

.u-display-i {
  display: inline;
}

.u-clearfix::before,
.u-clearfix::after {
  display: table;
  content: "";
}

.u-clearfix::after {
  clear: both;
}

.u-fw-300 {
  font-weight: 300;
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-600 {
  font-weight: 600;
}

.u-text-success,
.u-text-green {
  color: #40c426;
}

.u-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.layout {
  position: relative;
  background: $light-gray-2;
}

.layout__main {
  margin: auto;
  max-width: 100vw;
  padding: 65px 24px;
}

.layout__container {
  margin-top: 60px;

  img {
    display: block;
    margin: 0 auto;
  }
}

.layout__dimmer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  background: rgb(51 51 51 / 90%);
  opacity: 0;
  transition: opacity 200ms linear;
}

.layout__container > hr {
  height: 1px;
  opacity: 0.68;
  color: #ddd;
  border: solid 0.5px;
  margin-top: 60px;
  margin-bottom: 40px;
}

.slideout-open .layout__dimmer {
  z-index: 80;
  opacity: 1;
}

.layout__content__home {
  padding: 0;
}

.layout__container__home > hr {
  height: 1px;
  opacity: 0.68;
  color: #ddd;
  border: solid 0.5px;
  margin-top: 60px;
  margin-bottom: 40px;
}

.layout__content-sdk {
  padding: em(0) em(0) em(0);
}

.layout__container-sdk {
  margin-top: 0;
  margin: 0 auto;
}

.layout__container-sdk > hr {
  height: 1px;
  opacity: 0.68;
  color: #ddd;
  border: solid 0.5px;
  margin-top: 60px;
  margin-bottom: 40px;
}

.checkbox-container {
  display: flexbox;
  display: flex;
  margin-left: 1em;
  margin-right: 1em;
}

.checkbox-container h4 {
  margin: 0 0 0.75em;
}

.checkbox-container .col {
  display: block;
  width: 33%;
}

.checkbox-container .col .col-checkbox {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
}

#support-form h2 {
  margin: 1px !important;
}

#login-form {
  margin: 60px;
  display: none;
}

#congrats-success {
  display: none;
}

#congrats-error {
  display: none;
}

#basic-form {
  display: none;
}

.cards-form {
  padding: 1.11429em !important;
  height: auto;
  cursor: pointer;
  margin: 0.65em !important;
}

.cards-form .icon {
  width: 40%;
  float: left;
  margin-top: 8px !important;
  height: 100px;
}

.cards-form .text {
  width: 60%;
  float: right;
}

.cards-form .text p {
  margin-top: 10px;
  text-align: -webkit-left;
  font-size: 14px;
}

.cards-form .text h3 {
  text-align: -webkit-left;
  font-size: 18px;
}

.cards-form:hover {
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: 0 8px 16px #282e571a;
}

#congrats {
  margin-bottom: 180px;
  position: relative;
  z-index: 0;
  height: 300px;
  display: none;
}

#loading-congrats {
  display: none;
}

.message--gray {
  background: #4f5762;
}

.message--quiet--gray {
  border-left: 4px solid #4f5762;
}

.message__title-gray {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.message--loud.message__title--gray {
  font-size: 16px;
  font-weight: 600;
}

.message--quiet.message__title--gray {
  font-size: 14px;
  font-weight: normal;
}

.ui-button {
  font-size: 1.125em;
  padding: 0 1em;
  border-radius: 0.25em;
  border-width: 0.05556em;
  border-style: solid;
  line-height: 2.55556;
  user-select: none;
  transition: 75ms background linear, 75ms color linear, 75ms border linear;
}

.ui-button svg path {
  transition: 75ms fill linear;
}

.ui-button:focus {
  outline: 0;
}

.ui-button--primary {
  background: $andes-accent-color;
  border-color: $andes-accent-color;
  color: #fff;
}

.ui-button--primary:focus {
  background: $andes-accent-color;
  border-color: #008ad6;
  box-shadow: inset 0 0 0 0.0625em #008ad6;
}

.ui-button--primary:hover {
  background: var(--andes-color-blue-600);
  border-color: var(--andes-color-blue-600);
  cursor: pointer;
  color: #fff;
}

.ui-button--primary:active {
  background: #008ad6;
  border-color: #008ad6;
}

.ui-button--primary svg path {
  fill: #fff;
}

.ui-button--secondary {
  background: none;
  border-color: $andes-accent-color;
  color: $andes-accent-color;
}

.ui-button--secondary svg path {
  fill: $andes-accent-color;
}

.ui-button--secondary:focus {
  border-color: #008ad6;
  color: $andes-accent-color;
  background: #fff;
  box-shadow: inset 0 0 0 0.0625em #008ad6;
}

.ui-button--secondary:hover {
  border-color: var(--andes-color-blue-600);
  color: #fff;
  background: $andes-accent-color;
  cursor: pointer;
}

.ui-button--secondary:hover svg path {
  fill: #fff;
}

.ui-button--secondary:active {
  color: #fff;
  border-color: $andes-accent-color;
  background: $andes-accent-color;
}

.ui-button--secondary:active svg path {
  fill: #fff;
}

.ui-button--terciary {
  background: none;
  border-color: transparent;
  color: $andes-accent-color;
}

.ui-button--terciary svg path {
  fill: $andes-accent-color;
}

.ui-button--terciary:focus {
  border-color: #ddd;
}

.ui-button--terciary:hover {
  color: var(--andes-color-blue-600);
}

.ui-button--terciary:active {
  color: #008ad6;
}

.ui-button--terciary:active svg path {
  fill: #008ad6;
}

.ui-button--primary[disabled] {
  background: #ddd !important;
  border-color: #ddd !important;
  color: #fff !important;
}

.ui-button--primary[disabled] svg path {
  fill: #fff !important;
}

.ui-button--secondary[disabled] {
  border-color: #ddd !important;
  color: #ddd !important;
}

.ui-button--secondary[disabled] svg path {
  fill: #ddd !important;
}

.ui-button--terciary[disabled] {
  color: #999 !important;
}

.ui-button--terciary[disabled] svg path {
  fill: #999 !important;
}

.ui-button--small {
  font-size: 0.875em;
}

.ui-button__icon {
  display: inline-block;
  vertical-align: text-top;
  margin-right: 0.5em;
}

.ui-button__icon svg {
  display: block;
}

.navbar {
  position: fixed;
  display: flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  z-index: 70;
  width: 100%;
  height: 3.125em;
  padding: 0 0 0 1.5em;
  background: #fff;
  box-shadow: 0 0.1875em 0.4375em 0 rgb(40 52 128 / 10%);
}

.navbar .layout__content {
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar a:focus {
  text-decoration: none;
}

.navbar__logo {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 3.125em;
  height: 2.125em;
  overflow: hidden;
}

.navbar__logo-text {
  display: none;
}

.navbar__logo-iso {
  display: block;
  width: auto;
  height: 100%;
}

.navbar__menu {
  display: inline-grid;
}

.navbar__reference {
  display: none;
}

.navbar__support {
  display: none;
}

.navbar__container {
  height: 100%;
  display: flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.navbar__controls {
  display: flexbox;
  display: flex;
  height: 100%;
  text-align: right;
}

.navbar__search-form,
.form-search-icon {
  display: none;
}

.navbar__search,
.navbar__user {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.navbar__action {
  display: flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  padding: 0 0.875em;
}

.navbar__action svg path {
  fill: #9ba9bb;
}

.navbar__user-btn {
  margin-right: -0.375em;
}

.navbar__search-btn {
  position: relative;
}

.navbar__search-btn::after {
  position: absolute;
  top: 50%;
  right: -0.0625em;
  display: block;
  height: 1.125em;
  width: 0.0625em;
  margin-top: -0.5625em;
  background: #ddd;
  content: "";
}

.navbar-search-active .navbar__search-btn {
  position: inherit;
}

.navbar__navicon {
  height: 100%;
  display: flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  margin-left: -1.5em;
  padding: 0 1em;
}

.navbar__navicon span {
  display: inline-block;
  position: relative;
  width: 1.125em;
  height: 0.8em;
  border-top: 0.125em solid #999;
  border-bottom: 0.125em solid #999;
}

.navbar__navicon span::after {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #999;
  content: "";
  transform: translateY(-50%);
}

.navbar__search-input {
  padding: 0 2em 0 3.875em;
  border: 0;
  font-size: 0.875em;
}

.navbar__search-input:focus {
  outline: 0;
}

.navbar__search-input::input-placeholder {
  color: #999;
}

.navbar__search-input::placeholder {
  color: #999;
}

.navbar__search-input:input-placeholder {
  color: #999;
}

@supports (-webkit-text-fill-color: transparent) {
  .navbar__search-input {
    color: $andes-accent-color;
    text-shadow: 0 0 0 #637080;
    -webkit-text-fill-color: transparent;
  }

  .navbar__search-input::input-placeholder {
    text-shadow: 0 0 0 #999;
    -webkit-text-fill-color: #999;
  }

  .navbar__search-input::placeholder {
    text-shadow: 0 0 0 #999;
    -webkit-text-fill-color: #999;
  }

  .navbar__search-input:input-placeholder {
    text-shadow: 0 0 0 #999;
    -webkit-text-fill-color: #999;
  }
}

.navbar-search-active .navbar__search-form {
  display: block;
}

.navbar-search-active .navbar__search-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.navbar-search-active .search-icon {
  position: absolute;
  left: 1.5em;
  z-index: 2;
}

.navbar__search-reset {
  position: absolute;
  top: 0;
  right: 0.5em;
  z-index: 2;
  width: 2.5em;
  height: 100%;
  font-size: 1em;
  border: 0;
  padding: 0.625em 0;
  background: none;
}

.navbar__search-reset:focus {
  outline: 0;
}

.navbar__search-reset:focus::before,
.navbar__search-reset:focus::after {
  background: $andes-accent-color;
}

.navbar__search-reset::before,
.navbar__search-reset::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 0.0625em;
  height: 1.125em;
  margin-top: -0.625em;
  background: #637080;
}

.navbar__search-reset::before {
  transform: rotate(45deg);
}

.navbar__search-reset::after {
  transform: rotate(-45deg);
}

.navbar__user {
  position: relative;
  display: flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.navbar__user-menu {
  display: none;
  position: absolute;
  right: -6px;
  top: 150%;
  min-width: 9.375em;
  padding: 0.25em 0;
  margin-top: -0.25em;
  text-align: left;
  background: #fff;
  border-radius: 0.25em;
  box-shadow: 0 0 0.1875em 0 rgb(50 50 50 / 30%);
  animation: fade-in 200ms ease-out;
}

.navbar__user-menu::before {
  position: absolute;
  bottom: 100%;
  right: 0.8125em;
  width: 0.625em;
  height: 0.625em;
  margin-bottom: -0.3125em;
  background: #fff;
  transform: rotate(45deg);
  box-shadow: -1px -1px 1px 0 rgb(50 50 50 / 15%);
  content: "";
}

.navbar__user.is-active .navbar__user-menu {
  display: block;
}

.navbar__user-menu a {
  position: relative;
  display: block;
  padding: 0 1.14286em;
  font-size: 0.875em;
  line-height: 2.28571em;
  color: #666;
  white-space: nowrap;
  transition: background 100ms linear, color 100ms linear;
}

.navbar__user-menu a:hover,
.navbar__user-menu a:focus {
  background-color: rgb(215 230 239 / 50%);
  color: $andes-accent-color;
}

.navbar__user-menu hr {
  display: block;
  width: 100%;
  height: 1px;
  margin: 0.25em 0;
  background: #eee;
  border: 0;
}

.navbar__user-menu a.link-logout {
  color: $andes-accent-color;
}

.navbar__user-icon {
  height: 1.125em;
  width: auto;
}

.navbar__user-icon path {
  transition: fill 350ms ease-out;
}

.navbar__user.is-active .navbar__user-icon path,
.navbar__user-btn:hover .navbar__user-icon path,
.navbar__user-btn:focus .navbar__user-icon path {
  fill: $andes-accent-color;
}

.navbar__user-logged-btn {
  position: relative;
}

.user-menu-chevron {
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  border-right: 1px solid #666;
  border-bottom: 1px solid #666;
  transform: rotate(45deg);
}

.navbar__user-logged-btn img {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  object-fit: cover;
  margin-right: 0.5em;
  border-radius: 50%;
}

.navbar__user-nickname {
  display: none;
}

.navbar__login-button {
  border-radius: 4px;
  background-color: $andes-accent-color;
  width: auto;
  display: flexbox;
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
}

.navbar__login-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  text-align: center;
  color: #fff;
}

.navbar__login-button:hover {
  background-color: #007eb5;
  transition: background-color 0.2s ease-in;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -16.875em;
  z-index: 90;
  width: 16.875em;
  height: 100%;
  min-height: 100vh;
  background: #eff0f3;

  /* Chrome/Safari/Opera */

  /* Firefox */

  /* Internet Explorer/Edge */
  user-select: none;
}

.sidebar ul {
  padding: 0;
  margin-top: 0;
  list-style: none;
}

.sidebar ul li {
  margin-bottom: 0;
}

.sidebar a:focus {
  text-decoration: none;
}

.sidebar__menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 4.125em 0;
  border-right: 1px solid rgb(0 0 0 / 10%);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.sidebar__header-menu {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 3.125em;
  line-height: 3.125em;
  background: #fff;
}

.sidebar__header-trigger {
  display: block;
  width: 100%;
  padding-left: 1.33333em;
  font-size: 1.125em;
  color: #333;
}

.sidebar__header-trigger:active,
.sidebar__header-trigger:hover,
.sidebar__header-trigger:focus {
  color: #333;
}

.sidebar__header-options {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  background: #eff0f3;
}

.active + .sidebar__header-options {
  padding-top: 0.875em;
  height: 100vh;
}

.sidebar__l0 {
  position: relative;
  display: block;
  width: 100%;
  padding-left: 1.5em;
  font-size: 1em;
  font-weight: 600;
  line-height: 2.75em;
  color: #333;
}

.sidebar__l0:active,
.sidebar__l0:hover,
.sidebar__l0:focus {
  color: #333;
}

.sidebar__l1 {
  position: relative;
  width: 100%;
  display: block;
  display: flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 24px;
  font-size: 14px;
  padding-top: 11px;
  padding-bottom: 11px;
  font-weight: 600;
  text-decoration: none;
  color: #333;
  transition: color 150ms ease-out, background 150ms ease-out;
}

.sidebar__l1:focus {
  color: #333;
}

.sidebar__l1 svg [stroke*="#"],
.sidebar__l1 .globe svg path {
  fill: none;
  stroke: #333;
}

.sidebar__l1 + .sidebar__subgroup {
  margin-top: 0.5em;
}

.sidebar__l1-chevron,
.sidebar__l0-chevron {
  position: absolute;
  top: 0;
  right: 1.5em;
  height: 100%;
}

.accordion-trigger {
  position: relative;
}

.accordion-trigger .sidebar__l1-chevron::before,
.accordion-trigger .sidebar__l1-chevron::after {
  position: absolute;
  top: 50%;
  display: block;
  width: 8px;
  height: 1px;
  background: #999;
  transition: transform 150ms ease-out, background 150ms ease-out;
  content: "";
}

.accordion-trigger .sidebar__l1-chevron::before {
  right: 5px;
  transform: rotate(45deg);
}

.accordion-trigger .sidebar__l1-chevron::after {
  right: 0;
  transform: rotate(-45deg);
}

.sidebar__l0-chevron {
  display: block;
  top: 50%;
  right: 1.5em;
  width: 0.5em;
  height: 0.5em;
  margin-top: -0.25em;
  border-right: 1px solid #999;
  border-top: 1px solid #999;
  transform: rotate(45deg);
}

.sidebar__l1.active,
.sidebar__l1.active:hover {
  color: $andes-accent-color;
}

.sidebar__l1.active path.paint-fill,
.sidebar__l1.active:hover path.paint-fill {
  fill: $andes-accent-color;
}

.sidebar__l1.active svg [stroke*="#"],
.sidebar__l1.active .globe svg path,
.sidebar__l1.active:hover svg [stroke*="#"],
.sidebar__l1.active:hover .globe svg path {
  fill: none;
  stroke: $andes-accent-color;
}

.sidebar__subtitle {
  display: block;
  margin-left: 4.90909em;
  margin-right: 1.45455em;
  padding: 0.9em 0 0;
  line-height: 1.875em;
  font-size: 0.6875em;
  text-transform: uppercase;
  color: #666;
  border-top: 0.0625em solid #ccc;
  opacity: 0.5;
}

.sidebar__subtitle:first-child {
  border: 0;
}

.sidebar__subgroup {
  margin-bottom: 0.9em;
}

.sidebar__subgroup:last-child {
  margin-bottom: 1em;
}

.sidebar__link-item a {
  display: block;
  color: #666;
  padding: 0.625em;
  padding-left: 3.85714em;
  font-size: 0.875em;
  line-height: 1.42857;
  transition: color 150ms ease-out, background 150ms ease-out;
}

a.tagged {
  display: flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

a.with-icon {
  display: flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.tag-btn {
  margin-right: 10px;
  width: 50px;
  height: 16px;
  line-height: 16px;
  font-size: 10px;
  border-radius: 2px;
  color: #fff;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  font-weight: 700;
  text-align: center;
  outline: 0;
}

.tag-post {
  background-color: #4493e2;
  border-color: #4493e2;
}

.tag-put {
  background-color: #f2b622;
  border-color: #f2b622;
}

.tag-delete {
  background-color: #c60000;
  border-color: #c60000;
}

.tag-get {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.sidebar__link-item a.active,
.sidebar__link-item a.active:hover {
  color: $andes-accent-color;
  background: #cedfe8;
  border-left: 4px solid $andes-accent-color;
}

.sidebar__group {
  display: none;
  margin-bottom: 1.5em;
  animation: fade-in 200ms ease-out;
}

.sidebar__l1.active + .sidebar__group {
  display: block;
}

.active.accordion-trigger .sidebar__l1-chevron::before {
  transform: rotate(-45deg);
}

.active.accordion-trigger .sidebar__l1-chevron::after {
  transform: rotate(45deg);
}

.sidebar__icon {
  width: 1.5625em;
  margin-right: 0.5em;
  margin-right: 16px;
  vertical-align: middle;
}

.sidebar__icon svg {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.sidebar__menu hr {
  height: 1px;
  width: 246px;
  margin-right: 0;
  border-top: solid 1px rgb(0 0 0 / 7%);
  border-style: ridge;
  margin-bottom: 16px;
  margin-top: 16px;
}

.searh-results.is-active {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  width: 100%;
  height: 100%;
  padding: 5em 0;
  background: #f8f8f8;
  animation: fade-in 400ms ease-out;
  overflow: auto;
}

.search-results__box {
  max-width: 50em;
  margin: 1.5em;
  padding: 1.5em;
  background: #fff;
  border-radius: 0.25em;
  box-shadow: 0.125em 0.125em 0.25em 0 rgb(40 46 87 / 8%);
  animation: fade-in 300ms 100ms linear both;
}

.search-results__box ul {
  margin: 0;
  padding: 0;
}

.search-results__box li {
  list-style: none;
  margin: 0;
  border-bottom: 1px solid #dbdbdb;
}

.search-results__box li:last-child {
  border: 0;
}

.search-results__box li:last-child > a {
  padding-bottom: 0;
}

.search-results__box a {
  padding: 0.625em 0;
  display: block;
}

.search-results__box a:hover h3,
.search-results__box a:focus h3 {
  color: #008ad6;
}

.search-results__box a:focus {
  text-decoration: none;
}

.search-results__box h3 {
  font-size: 1.25em;
  margin-top: 0;
  margin-bottom: 0.3125em;
  transition: color 100ms ease-out;
}

.search-results__box p {
  font-size: 0.875em;
  margin-bottom: 0;
}

.search-results__card {
  margin: 0 !important;
}

.search-results__no-results {
  max-width: 25.625em;
  padding: 0 1.25em;
  margin: 6.25em auto;
  text-align: center;
}

.search-results__no-results h2 {
  font-size: 1em;
  line-height: 1.31;
  color: #637080;
  font-weight: 600;
}

.search-results__no-results h3 {
  font-size: 1em;
  line-height: 1.31;
  color: #9ba9bb;
}

.ui-dropdown {
  position: relative;
  display: inline-block;
  font-size: 0.875em;
}

.ui-dropdown__input,
.ui-dropdown__options {
  display: none;
}

.ui-dropdown__trigger {
  position: relative;
  padding-right: 1em;
  font-weight: 400;
  color: $andes-accent-color;
  cursor: pointer;
}

.ui-dropdown__trigger::after {
  position: absolute;
  top: 50%;
  right: -0.5em;
  width: 0.375em;
  height: 0.375em;
  border-bottom: 0.0625em solid #999;
  border-right: 0.0625em solid #999;
  content: "";
  transform: rotate(45deg) translateY(-100%);
}

.ui-dropdown__options {
  position: absolute;
  bottom: -0.625em;
  left: -1.875em;
  z-index: 1;
  background: #fff;
  border-radius: 0.25em;
  box-shadow: 0 0.0625em 0.125em 0 rgb(0 0 0 / 30%);
  animation: fade-in 200ms ease-out;
}

.ui-dropdown__option {
  display: block;
  height: 3.92857em;
  padding: 0 1.71429em;
  line-height: 3.92857em;
  color: #444;
  border-bottom: 0.0625em solid #eee;
}

.ui-dropdown__option:hover {
  background: #ebf2f7;
}

.ui-dropdown__option.is-active {
  box-shadow: inset 0.1875em 0 0 0 $andes-accent-color;
}

.ui-dropdown__option:last-child {
  border: 0;
}

:checked + .ui-dropdown__select .ui-dropdown__options {
  display: block;
}

.tree-table {
  margin-top: 2em;
  box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 8%);
}

.tree-table .main-row .tree-table-row.idented-1 {
  display: none;
}

.tree-table .main-row .tree-table-row.idented-2 {
  display: none;
}

.tree-table .main-row .tree-table-row.idented-3 {
  display: none;
}

.tree-table .main-row .tree-table-row .chevron {
  width: 15px;
  padding: 0;
}

.tree-table .main-row .tree-table-row .dropdown__trigger .chevron::before {
  width: 0.5em;
  height: 0.5em;
  display: block;
  border-bottom: 0.0625em solid #999;
  border-right: 0.0625em solid #999;
  content: "";
  transform: rotate(45deg);
}

.tree-table .main-row.active .tree-table-row.idented-1 {
  display: flexbox;
  display: flex;
}

.tree-table .main-row.active .tree-table-row.idented-2 {
  display: flexbox;
  display: flex;
}

.tree-table .main-row.active .tree-table-row.idented-3 {
  display: flexbox;
  display: flex;
}

.tree-table
  .main-row.active
  .tree-table-row
  .dropdown__trigger
  .chevron::before {
  transform: rotate(-135deg) translateX(-50%) translateY(-50%);
}

.tree-table-header {
  display: flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #ddd;
  font-weight: 600;
}

.tree-table-header span:first-child {
  padding: 10px 10px 10px 30px;
  display: flexbox;
  display: flex;
  width: 35%;
  border-right: 1px #ddd solid;
}

.slug {
  color: #c60000;
}

.tree-table-header span:nth-child(2) {
  padding: 10px;
  width: 15%;
  border-right: 1px #ddd solid;
}

.tree-table-header span:nth-child(3) {
  padding: 10px;
  width: 50%;
}

.tree-table-row span.required::after {
  position: relative;
  display: block;
  bottom: 2px;
  content: "REQUIRED";
  font-size: 0.7em;
  color: #f4b446;
}

.tree-table-row span.idented-1 {
  padding-left: 60px !important;
}

.tree-table-row span.idented-2 {
  padding-left: 90px !important;
}

.tree-table-row span.idented-3 {
  padding-left: 120px !important;
}

.tree-table-row span {
  font-size: 1em;
}

.tree-table-row {
  display: flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.tree-table-row.clickable {
  cursor: pointer;
}

.tree-table-row.clickable:hover {
  background: #f7f7f7;
}

.tree-table-row span {
  font-size: 0.875em;
}

.tree-table-row span:first-child {
  display: flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 700;
  width: 35%;
  padding: 10px 10px 10px 15px;
}

.tree-table-row span:nth-child(2) {
  width: 15%;
  padding: 10px;
}

.tree-table-row span:nth-child(3) {
  width: 50%;
  padding: 10px;
  padding-right: 35px;
}

.enum-container {
  margin-top: 10px;
}

.enum-row {
  display: flexbox;
  display: flex;
  line-height: 1.5;
  -ms-flex-align: center;
  align-items: center;
}

.enum-row span {
  padding-bottom: 2px !important;
  padding-top: 2px !important;
}

.enum-row span:first-child {
  color: #53b768;
}

.enum-row span:nth-child(2) {
  width: 50%;
}

.two-table {
  margin-top: 2em;
  box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 8%);
}

.two-table-header {
  display: flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #ddd;
  font-weight: 600;
}

.two-table-header span:first-child {
  padding: 10px 10px 10px 36px;
  display: flexbox;
  display: flex;
  width: 35%;
  border-right: 1px #ddd solid;
}

.slug {
  color: #c60000;
}

.two-table-header span:nth-child(2) {
  padding: 10px;
  width: 60%;
  border-right: 1px #ddd solid;
}

.two-table-row span.dropdown__trigger::before {
  width: 0.5em;
  height: 0.5em;
  position: relative;
  display: block;
  left: -21px;
  bottom: -18px;
  border-bottom: 0.0625em solid #999;
  border-right: 0.0625em solid #999;
  content: "";
  transform: rotate(45deg) translateY(-100%);
}

.two-table-row span.required::after {
  position: relative;
  display: block;
  bottom: 2px;
  content: "REQUIRED";
  font-size: 0.7em;
  color: #f4b446;
}

.two-table-row span.idented-1 {
  padding-left: 60px !important;
}

.two-table-row span.idented-2 {
  padding-left: 90px !important;
}

.two-table-row span.idented-3 {
  padding-left: 120px !important;
}

.two-table-row span {
  font-size: 1em;
}

.two-table-row {
  display: flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.two-table-row.clickable {
  cursor: pointer;
}

.two-table-row.clickable:hover {
  background: #f7f7f7;
}

.two-table-row span {
  font-size: 0.875em;
}

.two-table-row span:first-child {
  font-weight: 700;
  width: 35%;
  padding: 10px 10px 10px 36px;
  word-break: break-word;
}

.two-table-row span:nth-child(2) {
  width: 60%;
  padding: 10px;
  padding-right: 35px;
}

.enum-container {
  margin-top: 10px;
}

.enum-row {
  display: flexbox;
  display: flex;
  line-height: 1.5;
  -ms-flex-align: center;
  align-items: center;
}

.enum-row span {
  padding-bottom: 2px !important;
  padding-top: 2px !important;
}

.enum-row span:first-child {
  color: #53b768;
}

.enum-row span:nth-child(2) {
  width: 65%;
}

.c-404 {
  margin-top: 2.5em;
}

.c-404__img {
  display: block;
  margin: 0 auto;
  margin-bottom: 1em;
}

.c-404__img svg {
  display: block;
  margin: 0 auto;
}

.c-404__title {
  max-width: 12.5em;
  margin: 0 auto;
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  color: #333;
}

.c-404__img-arrows {
  animation: compass-lost 3.5s ease-in-out infinite;
}

@keyframes compass-lost {
  0% {
    transform: rotate(-20deg);
    transform-origin: center;
  }

  50% {
    transform: rotate(20deg);
    transform-origin: center;
  }

  100% {
    transform: rotate(-20deg);
    transform-origin: center;
  }
}

.card-status-tag {
  margin-right: -24px !important;
  width: auto;
  height: 16px;
  line-height: 16px;
  font-size: 10px;
  border-radius: 0 4px !important;
  color: #fff;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  font-weight: 700;
  text-align: center;
  outline: 0;
  padding: 0 8px !important;
  float: right !important;
  visibility: visible;
}

.card-status-tag-required {
  background-color: $andes-accent-color;
  border-color: $andes-accent-color;
  margin-top: -24px !important;
}

.card-status-tag-recommended {
  background-color: #00a650;
  border-color: #00a650;
  margin-top: -24px !important;
}

@media (width <= 324px) {
  .card-status-tag {
    visibility: hidden;
  }
}

.card,
.home-detail-card-container,
.card-dummy,
blockquote {
  background: #fff;
  overflow: hidden;
  box-shadow: 0 0.07em 0.125em 0 rgb(0 0 0 / 15%);
  font-size: 0.875em;
  border-radius: 6px;
  padding: 46px;
  margin: 32px 0;
}

blockquote.card,
blockquote.home-detail-card-container,
blockquote.card-dummy {
  box-shadow: none;
}

.card :last-child,
.home-detail-card-container :last-child,
.card-dummy :last-child,
blockquote :last-child {
  margin-bottom: 0;
}

.card :first-child,
.home-detail-card-container :first-child,
.card-dummy :first-child,
blockquote :first-child {
  margin-top: 0;
}

.card ul,
.home-detail-card-container ul,
.card-dummy ul,
.card ol,
.home-detail-card-container ol,
.card-dummy ol,
blockquote ul,
blockquote ol {
  padding-left: 46px;
  margin: 1.14286em 0;
}

.card li,
.home-detail-card-container li,
.card-dummy li,
blockquote li {
  margin: 0;
}

.card-info p:first-child,
.card-warning p:first-child {
  font-size: 1.14286em;
  margin-bottom: 0.28571em;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #f57819;
}

.card-info p:first-child::before,
.card-warning p:first-child::before {
  height: 1.3125em;
  width: 1.3125em;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid #f57819;
  content: "!";
  text-align: center;
  margin-right: 0.5em;
  font-weight: 600;
  line-height: 1.0625em;
  padding-left: 0.0625em;
}

.card-note {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  background: none;
  box-shadow: none;
}

.card-note p {
  margin: 0;
  overflow: hidden;
}

.card-note p:first-child {
  float: left;
  padding: 0.25em 0.625em 0.25em 0.6875em;
  margin-right: 1em;
  font-size: 0.75em;
  font-weight: 600;
  letter-spacing: 0.0625em;
  text-transform: uppercase;
  border: 1px solid $andes-accent-color;
  border-radius: 50px;
  color: $andes-accent-color;
}

.card-index {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  background: none;
  box-shadow: none;
}

.card-index p {
  margin: 0;
  overflow: hidden;
  margin-bottom: 4px;
}

.card-index p:first-child {
  font-size: 1em;
  margin: 0.2em 0 0.75em;
  color: #04204c;
  font-weight: 600;
}

.card-highlight {
  font-size: 1em;
  text-align: center;
  margin: 24px 0;
  text-decoration: none;
}

.card-highlight:active {
  text-decoration: none;
}

.card-highlight-icon,
.card-highlight-icon:first-child {
  display: block;
  margin: 0.75em 0;
}

.card-highlight-icon svg {
  display: block;
  width: auto;
  max-height: 6.875em;
  margin: 0 auto;
}

.card-highlight h3 {
  margin-top: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  color: rgb(0 0 0 / 80%);
}

.card__link-icon {
  display: inline-block;
  width: 0.625em;
}

.card-actions {
  margin-top: 1.5em;
}

.arrow-link {
  display: block;
  margin: 0.25em 0;
}

.arrow-link:focus {
  text-decoration: none;
}

.arrow-link__icon {
  display: inline-block;
  height: 0.5625em;
  width: 0.5625em;
  visibility: visible;
  transition: transform 120ms ease-out;
}

.arrow-link:hover .arrow-link__icon,
.arrow-link:focus .arrow-link__icon {
  transform: translateX(0.3125em);
}

.iconsgrid {
  display: flexbox;
  display: flex;
  margin-left: 26px;
  margin-top: 48px;
}

.iconsgrid a {
  display: block;
  width: 64px;
  height: 64px;
  margin-right: 19px;
  background: #fff;
  overflow: hidden;
  border-radius: 0.25em;
  font-size: 0.875em;
  box-shadow: 0.07143em 0.14286em 0.21429em 0 rgb(0 0 0 / 10%);
}

.pl-icons-grid {
  display: flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}

.pl-icons-grid a {
  display: block;
  width: 96px;
  height: 96px;
}

#credentials-card h3 {
  margin: 2em 0 0;
}

#credentials-card .card-inner {
  margin-left: 1em;
  margin-right: 1em;
}

#version-warning-container {
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 2em;
}

#version-warning-container span.wording {
  font-size: 0.77778em;
}

#version-warning-container ul#version-list {
  margin: 0.5em 0;
  line-height: 0.5em;
}

#version-warning-container ul#version-list li {
  font-size: 0.77778em;
}

.tooltip {
  display: inline-block;
}

.tooltip svg {
  fill: #4d5763;
  margin-right: 4px;
}

.link-href {
  text-decoration: none;
  color: #4d5763;
}

.link-href:hover,
.link-href:focus {
  color: #4d5763;
  text-decoration: none;
}

.tooltip-content {
  display: none;
  width: 250px;
  background-color: white;
  color: #fff;
  color: gray;
  left: -75px;
  top: 30px;
  border-radius: 6px;
  padding: 15px;

  /* Position the tooltip */
  z-index: 1;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%), 0 1px 1px 0 rgb(0 0 0 / 20%);
  box-sizing: border-box;
  text-align: left;
  opacity: 0;
  position: absolute;
}

.tooltip-content i {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip-text {
  font-size: 0.875em;
  font-weight: 400;
  margin: 0;
}

.tooltip-title {
  margin: 0 0 12px;
  padding: 0;
  font-weight: 600;
}

.tooltip-content i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #fff;
  box-shadow: 0 1px 8px #eee;
}

.tooltip:hover .tooltip-content {
  display: inherit;
  opacity: 1;
  transition: all 480ms ease-in-out;
}

.like-widget {
  background: #eff0f3;
  box-shadow: none;
  padding: 32px;
  display: none;
  margin-bottom: 0 !important;
}

.title__like {
  font-size: 18px;
  line-height: 1.56;
  color: #04204c;
}

.satisfaction-button-container {
  margin-top: 16px;
}

.satisfaction-title-container {
  width: 100%;
}

.andes-button {
  display: inline-table;
}

.andes-button--small {
  margin-top: 16px;
  display: inline-block;
}

.satisfaction-answer {
  font-size: 16px;
  margin-top: 24px;
  float: left;
  display: none;
}

.satisfaction-answer.p {
  float: left;
}

* {
  -webkit-tap-highlight-color: rgb(255 255 255 / 0%) !important;
  -webkit-focus-ring-color: rgb(255 255 255 / 0%) !important;
  outline: none !important;
}

.home-card:hover {
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 10%);
}

.home-card p {
  font-size: 14px;
  text-decoration: none;
  line-height: 1.29;
  color: rgb(0 0 0 / 80%);
}

.home-card p:active {
  text-decoration: none;
}

.home-first-partial {
  max-width: 50.875em;
  margin: 0 auto;
  padding: 0 0 106px;
}

.home-card-partial {
  max-width: 50.875em;
  margin: 40px 10px;
  padding-right: 16px;
  padding-left: 16px;
}

.h3-banner-title,
.h2-banner-title {
  height: auto;
  margin-top: 0;
}

.payments-card-link,
.payments-card-link-second {
  z-index: 2;
  max-width: 258px;
}

.payments-card-link:active,
.payments-card-link-second:active {
  text-decoration: none;
}

.payments-card-link:focus,
.payments-card-link-second:focus {
  text-decoration: none;
}

.home-footer-cards-container {
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 108px;
}

.card-middle {
  border-top: solid 1px #e2e2e4;
  border-bottom: solid 1px #e2e2e4;
}

.home-footer-card {
  padding-top: 31px;
  padding-bottom: 32px;
}

.h4-title-card {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.29;
  color: rgb(0 0 0 / 80%);
  max-width: 200px;
}

.home-footer-card a {
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  color: $andes-accent-color;
  text-decoration: none;
}

.footer-icon-home {
  margin-top: 18px;
}

.home-container-first {
  display: flexbox;
  display: flex;
  flex-flow: column-reverse nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.home-title-container {
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
}

.home-container-first h3 {
  font-size: 10px;
  font-weight: 600;
  line-height: 0.76;
  letter-spacing: 2.5px;
  color: rgb(0 0 0 / 80%);
  text-transform: uppercase;
}

.home-container-first h1 {
  font-size: 36px;
  font-weight: bold;
  line-height: 1.15;
  color: rgb(0 0 0 / 80%);
  margin-top: 65px;
  margin-bottom: 20px;
}

.home-container-first p {
  font-size: 14.4px;
  line-height: 1.25;
  color: rgb(0 0 0 / 45%);
}

.home-image-container {
  text-align: center;
}

.home-image-container svg {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 40px;
  margin-bottom: 8px;
}

.home-banner-container {
  margin-top: 56px;
  margin-bottom: 9px;
  background-color: $andes-accent-color;
  width: 100%;
  height: auto;
  z-index: 1;
}

.home-banner-content {
  max-width: 50.875em;
  margin: 0 auto;
}

.imagen-banner-container {
  height: auto;
}

.home-banner-title {
  height: auto;
  padding: 24px;
}

.home-banner-title h3 {
  font-size: 10px;
  font-weight: 600;
  line-height: 1.06;
  letter-spacing: 0.5px;
  color: #fff;
}

.home-banner-title h2 {
  font-size: 20px;
  max-width: 300px;
  font-weight: bold;
  line-height: 1.2;
  color: #fff;
  margin-top: 12px;
  margin-bottom: 0;
}

.home-banner-button {
  display: none;
}

.home-banner-link {
  display: block;
  font-size: 16px;
  margin-top: 24px;
  font-weight: 600;
  line-height: 1;
  color: #fff;
}

.home-banner-link:active {
  text-decoration: none;
  color: #fff;
}

.home-banner-link:focus {
  text-decoration: none;
  color: #fff;
}

.home-banner-link:hover {
  text-decoration: none;
  color: #fff;
}

.imagen-banner-container {
  display: none;
}

.imagen-banner-container-mobile {
  text-align: right;
  padding-top: 28px;
}

.home-detail-title-container {
  max-width: 866px;
  margin: 0 auto;
  margin-bottom: 54px;
}

.home-detail-title-container h1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.25;
  color: rgb(0 0 0 / 80%);
  margin-top: 40px;
  margin-left: 26px;
  margin-right: 26px;
}

.home-detail-title-container p {
  font-size: 16px;
  line-height: 1.25;
  color: rgb(0 0 0 / 80%);
  margin-top: 8px;
  margin-left: 26px;
  margin-right: 26px;
}

.home-detail-back-container {
  display: flexbox;
  display: flex;
  margin: 0 auto;
  max-width: 866px;
  margin-top: 33px;
}

.home-detail-back-plug {
  margin-left: 0 !important;
}

.home-detail-back-image {
  margin-right: 12px;
  margin-left: 26px;
}

.home-detail-card-container:hover {
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 10%);
}

.home-detail-card {
  max-width: 50.875em;
  margin: 0 10px;
  padding-right: 16px;
  padding-left: 16px;
}

.home-detail-card:first-child {
  margin-left: 26px;
}

.home-detail-card-icon svg {
  display: block;
  margin-top: 10px;
  width: auto;
}

.home-detail-card-highlight {
  text-align: left;
  font-size: 1em;
  margin: 1em 0;
  margin-top: 16px;
}

.home-detail-card-container {
  padding: 24px;
}

.home-detail-card-h3 {
  font-size: 16px;
  font-weight: 600;
  margin-top: 16px;
  color: rgb(0 0 0 / 80%);
}

.home-detail-card-p {
  font-size: 14px;
  line-height: 1.29;
  margin-top: 16px;
  color: rgb(0 0 0 / 80%);
}

.home-detail-second-title-container-h3 {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin: 48px 26px 24px;
}

.home-detail-second-title-container {
  margin: 0 auto;
  max-width: 866px;
}

.card-dummy {
  visibility: hidden;
}

.payments-card-link-second:active {
  text-decoration: none;
}

.payments-card-link-second:focus {
  text-decoration: none;
}

.home-detail-back {
  display: flexbox;
  display: flex;
  text-decoration: none;
}

.home-detail-back:hover .home-detail-back-image path {
  fill: var(--andes-color-blue-600);
  color: var(--andes-color-blue-600);
}

.home-detail-back:hover .home-detail-back-title {
  color: var(--andes-color-blue-600);
}

.home-detail-back:focus {
  text-decoration: none;
}

.homepl-detail-back-container {
  display: flexbox;
  display: flex;
  margin: 0 auto;
  max-width: 940px;
  margin-top: 33px;
}

.home-detail-back-image {
  margin-right: 12px;
  margin-left: 11px;
}

.homepl-detail-title-container {
  max-width: 940px;
  margin: 0 auto;
  margin-bottom: 36.1px;
}

.homepl-detail-title-container h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  color: rgb(0 0 0 / 80%);
  margin: 40px 16px 0;
  padding-right: 90px;
}

.home-detail-title-h3-mob {
  font-size: 16px;
  line-height: 1.25;
  color: rgb(0 0 0 / 80%);
  margin: 12px 16px 0;
}

.home-detail-title-h3 {
  display: none;
}

.homepl-detail-second-title-container {
  margin: 0 auto;
  max-width: 940px;
}

.homepl-detail-second-title-container-h2 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin: 37px 16px 16px;
}

.homepl-detail-card {
  max-width: 50.875em;
  margin: 0 16px;
}

.homepl-detail-card-icon {
  display: flexbox;
  display: flex;
  margin-right: 12px;
  -ms-flex-align: center;
  align-items: center;
}

.homepl-detail-card-icon svg {
  display: block;
  width: auto;
}

.homepl-detail-card-container {
  display: flexbox;
  display: flex;
  padding: 24px 24px 24px 16px;
  margin-bottom: 24px;
}

.home-detail-card-container:hover {
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 10%);
}

.home-text-card-h3 {
  font-size: 16px;
  font-weight: 600;
  color: rgb(0 0 0 / 80%);
  margin: 0;
}

.home-text-card-p {
  font-size: 14px;
  line-height: 1.29;
  margin-top: 8px;
  margin-bottom: 0;
  color: rgb(0 0 0 / 80%);
}

.card-dummy {
  visibility: hidden;
}

.payments-card-link-second-pl:active {
  text-decoration: none;
}

.payments-card-link-second-pl:focus {
  text-decoration: none;
}

.payments-card-link_pl,
.payments-card-link-second-pl {
  z-index: 2;
  width: 100%;
}

.payments-card-link_pl:active,
.payments-card-link-second-pl:active {
  text-decoration: none;
}

.payments-card-link_pl:focus,
.payments-card-link-second-pl:focus {
  text-decoration: none;
}

.cardpl,
.homepl-detail-card-container,
.card-dummy,
blockquote {
  background: #fff;
  overflow: hidden;
  box-shadow: 0 0.07em 0.125em 0 rgb(0 0 0 / 15%);
  font-size: 0.875em;
  border-radius: 6px;
  padding: 24px 24px 24px 16px;
}

blockquote.cardpl,
blockquote.homepl-detail-card-container,
blockquote.card-dummy {
  box-shadow: none;
}

.homepl-banner {
  margin: 0 auto;
  max-width: 940px;
}

.homepl-banner-container {
  display: flexbox;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 12%);
  background-color: #fff;
  margin: 36.1px 16px 36.9px;
}

.homepl-banner-text {
  padding: 23.9px 24px 43px;
}

.homepl-banner-p {
  font-size: 12px;
  color: rgb(0 0 0 / 80%);
  background-color: #fff;
  margin: 0;
}

.homepl-banner-h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  color: rgb(0 0 0 / 80%);
  border-radius: 6px;
  background-color: #fff;
  padding: 6px 24px 28px 0;
  margin: 0;
}

.homepl-banner-h1-shopify {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  color: rgb(0 0 0 / 80%);
  border-radius: 6px;
  background-color: #fff;
  padding: 6px 24px 44px 0;
  margin: 0;
}

.homepl-banner-image {
  padding: 0 20px 35px 23px;
  max-width: 250px;
}

.homepl-banner-card-link:hover {
  text-decoration: none;
  margin-right: 130px;
}

.homepl-banner-button {
  padding: 0 38px;
  font-size: 0.875em;
  line-height: 2.7142857142857144;
  background: $andes-accent-color;
  border-radius: 4px;
  border: 0;
  width: 210px;
  text-align: center;
}

.homepl-banner-button:hover {
  text-decoration: none;
  cursor: pointer;
  background-color: #007eb5;
  transition: background-color 0.2s ease-in;
}

.titlepl-banner-button {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.andes-widther-support {
  width: 100%;
}

.andes-widther {
  display: flexbox;
  display: flex;
  width: 100%;
  max-width: 512px;
}

.andes-width--25 {
  width: 25%;
  max-width: initial;
}

.andes-width--50 {
  width: 50%;
  max-width: initial;
}

.andes-width--75 {
  width: 75%;
  max-width: initial;
}

.andes-width--100 {
  width: 100%;
  max-width: initial;
}

.andes-width--150 {
  width: 150%;
  max-width: initial;
}

.andes-width--200 {
  width: 200%;
  max-width: initial;
}

.andes-widther-support {
  width: 100%;
}

.andes-widther {
  display: flexbox;
  display: flex;
  width: 100%;
  max-width: 512px;
}

.andes-width--25 {
  width: 25%;
  max-width: initial;
}

.andes-width--50 {
  width: 50%;
  max-width: initial;
}

.andes-width--75 {
  width: 75%;
  max-width: initial;
}

.andes-width--100 {
  width: 100%;
  max-width: initial;
}

.andes-width--150 {
  width: 150%;
  max-width: initial;
}

.andes-width--200 {
  width: 200%;
  max-width: initial;
}

.andes-button {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.125em;
  font-weight: 400;
  line-height: 1;
  padding: 0.77778em 1.77778em;
  text-align: center;
  border-radius: 0.25em;
  transition: 0.18s ease-out;
  transition-property: background, color;
  -webkit-font-smoothing: antialiased;
}

.andes-button:link {
  text-decoration: none;
}

.andes-button,
.andes-button * {
  box-sizing: border-box;
}

.andes-button svg {
  display: inline-block;
  vertical-align: middle;
  max-width: 1em;
  max-height: 1em;
}

.andes-button__content {
  display: block;
}

.andes-button__content::after {
  content: "";
  display: table;
  clear: both;
}

.andes-button__content * {
  display: block;
  float: left;
}

.andes-button__text + svg,
svg + .andes-button__text {
  margin-left: 0.88889em;
}

.satisfaction-button-container {
  .andes-button--small {
    font-size: 16px !important;
    font-weight: 400 !important;
    padding: 0.6875em 1.5em !important;
    line-height: 1 !important;
    margin-top: 0 !important;
  }
}

.andes-button--small {
  font-size: 16px;
  padding: 0.6875em 1.5em;
}

.andes-button--small svg {
  display: none;
}

.andes-button--small .andes-button__text {
  margin: 0;
}

.andes-button--filled {
  border: 0.05556em solid;
  cursor: pointer;
  transition: box-shadow 0.25s ease-out, background-color 0.2s ease-out;
  box-shadow: 0 0 0 0 #fff;
}

.andes-button--filled,
.andes-button--filled:link,
.andes-button--filled:visited,
.andes-button--filled:focus {
  border-color: #3483fa;
  background-color: #3483fa;
  color: #fff;
}

.andes-button--filled path,
.andes-button--filled:link path,
.andes-button--filled:visited path,
.andes-button--filled:focus path {
  stroke: #fff !important;
}

.andes-button--filled:hover {
  border-color: #1e6dff;
  background-color: #1e6dff;
  color: #fff;
  transition: background-color 0.2s ease-in;
}

.andes-button--filled:hover path {
  stroke: #fff !important;
}

.andes-button--filled:active {
  border-color: #105cf4;
  background-color: #105cf4;
  color: #fff;
}

.andes-button--filled:active path {
  stroke: #fff !important;
}

.andes-button--filled:disabled,
.andes-button--filled[disabled] {
  border-color: rgb(0 0 0 / 5%);
  background-color: rgb(0 0 0 / 5%);
  color: rgb(0 0 0 / 30%);
  cursor: default;
  background-clip: padding-box;
}

.andes-button--filled:disabled path,
.andes-button--filled[disabled] path {
  stroke: rgb(0 0 0 / 30%) !important;
}

.andes-button--filled:focus {
  outline: 0;
  box-shadow: 0 0 0 0.16667em rgb(30 109 255 / 40%);
  transition: box-shadow 0.25s ease-in;
}

.andes-button--outline {
  border: 0.05556em solid;
  cursor: pointer;
  transition: box-shadow 0.25s ease-out, background-color 0.2s ease-out;
  box-shadow: 0 0 0 0 #fff;
}

.andes-button--outline,
.andes-button--outline:link,
.andes-button--outline:visited,
.andes-button--outline:focus {
  border-color: #3483fa;
  background-color: transparent;
  color: #3483fa;
}

.andes-button--outline path,
.andes-button--outline:link path,
.andes-button--outline:visited path,
.andes-button--outline:focus path {
  stroke: #3483fa !important;
}

.andes-button--outline:hover {
  border-color: #2c6bff;
  background-color: rgb(52 131 250 / 6%);
  color: #2c6bff;
  transition: background-color 0.2s ease-in;
}

.andes-button--outline:hover path {
  stroke: #2c6bff !important;
}

.andes-button--outline:active {
  border-color: #2c6bff;
  background-color: rgb(52 131 250 / 12%);
  color: #2c6bff;
}

.andes-button--outline:active path {
  stroke: #2c6bff !important;
}

.andes-button--outline:disabled,
.andes-button--outline[disabled] {
  border-color: rgb(51 51 51 / 20%);
  background-color: #fff;
  color: rgb(51 51 51 / 30%);
  cursor: default;
  background-clip: padding-box;
}

.andes-button--outline:disabled path,
.andes-button--outline[disabled] path {
  stroke: rgb(51 51 51 / 30%) !important;
}

.andes-button--outline:focus {
  outline: 0;
  box-shadow: 0 0 0 0.16667em rgb(52 131 250 / 15%);
  transition: box-shadow 0.25s ease-in;
}

.andes-button--transparent {
  border: 0.05556em solid;
  cursor: pointer;
  transition: box-shadow 0.25s ease-out, background-color 0.2s ease-out;
  box-shadow: 0 0 0 0 #3483fa;
}

.andes-button--transparent,
.andes-button--transparent:link,
.andes-button--transparent:visited,
.andes-button--transparent:focus {
  border-color: transparent;
  background-color: transparent;
  color: #3483fa;
}

.andes-button--transparent path,
.andes-button--transparent:link path,
.andes-button--transparent:visited path,
.andes-button--transparent:focus path {
  stroke: #3483fa !important;
}

.andes-button--transparent:hover {
  border-color: transparent;
  background-color: rgb(52 131 250 / 6%);
  color: #2c6bff;
  transition: background-color 0.2s ease-in;
}

.andes-button--transparent:hover path {
  stroke: #2c6bff !important;
}

.andes-button--transparent:active {
  border-color: transparent;
  background-color: rgb(52 131 250 / 12%);
  color: #2c6bff;
}

.andes-button--transparent:active path {
  stroke: #2c6bff !important;
}

.andes-button--transparent:disabled,
.andes-button--transparent[disabled] {
  border-color: #fff;
  background-color: #fff;
  color: rgb(0 0 0 / 30%);
  cursor: default;
  background-clip: padding-box;
}

.andes-button--transparent:disabled path,
.andes-button--transparent[disabled] path {
  stroke: rgb(0 0 0 / 30%) !important;
}

.andes-button--transparent:focus {
  outline: 0;
  box-shadow: 0 0 0 0.16667em rgb(52 131 250 / 30%);
  transition: box-shadow 0.25s ease-in;
}

.andes-button--white {
  border: 0.05556em solid;
  cursor: pointer;
  transition: box-shadow 0.25s ease-out, background-color 0.2s ease-out;
  box-shadow: 0 0 0 0;
}

.andes-button--white,
.andes-button--white:link,
.andes-button--white:visited,
.andes-button--white:focus {
  border-color: transparent;
  background-color: rgb(255 255 255 / 0%);
  color: #fff;
}

.andes-button--white path,
.andes-button--white:link path,
.andes-button--white:visited path,
.andes-button--white:focus path {
  stroke: #fff !important;
}

.andes-button--white:hover {
  border-color: none;
  background-color: rgb(255 255 255 / 4%);
  color: #fff;
  transition: background-color 0.2s ease-in;
}

.andes-button--white:hover path {
  stroke: #fff !important;
}

.andes-button--white:active {
  border-color: none;
  background-color: rgb(255 255 255 / 4%);
  color: #fff;
}

.andes-button--white:active path {
  stroke: #fff !important;
}

.andes-button--white:disabled,
.andes-button--white[disabled] {
  border-color: rgb(255 255 255 / 0%);
  background-color: rgb(255 255 255 / 0%);
  color: rgb(0 0 0 / 30%);
  cursor: default;
  background-clip: padding-box;
}

.andes-button--white:disabled path,
.andes-button--white[disabled] path {
  stroke: rgb(0 0 0 / 30%) !important;
}

.andes-button--white:focus {
  outline: 0;
  box-shadow: 0 0 0 0.16667em none;
  transition: box-shadow 0.25s ease-in;
}

.andes-button {
  width: 100%;
  display: block;
}

.andes-button + .andes-button {
  margin-top: 0.44444em;
}

.andes-button--small {
  width: auto;
  display: inline-block;
}

.andes-button--small + .andes-button--small {
  margin: 0.5em 0 0 0.5em;
}

@media (width >= 768px) {
  .andes-button {
    display: inline-block;
    width: auto;
    min-width: 7.88889em;
  }

  .andes-button + .andes-button {
    margin: 0 0 0 0.44444em;
  }

  .andes-button--small {
    min-width: 0;
  }

  .andes-button--small + .andes-button--small {
    margin: 0 0 0 0.5em;
  }
}

.andes-widther-support {
  width: 100%;
}

.andes-widther {
  display: flexbox;
  display: flex;
  width: 100%;
  max-width: 512px;
}

.andes-width--25 {
  width: 25%;
  max-width: initial;
}

.andes-width--50 {
  width: 50%;
  max-width: initial;
}

.andes-width--75 {
  width: 75%;
  max-width: initial;
}

.andes-width--100 {
  width: 100%;
  max-width: initial;
}

.andes-width--150 {
  width: 150%;
  max-width: initial;
}

.andes-width--200 {
  width: 200%;
  max-width: initial;
}

.andes-form-control {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  position: relative;
  padding-top: 0.77778em;
  font-weight: 400;
  display: block;
  -webkit-font-smoothing: antialiased;
  text-align: left;
}

.andes-form-control__control {
  position: relative;
}

.andes-form-control__control,
.andes-form-control__label,
.andes-form-control__field,
.andes-form-control__message,
.andes-form-control__border {
  display: block;
  width: 100%;
}

.andes-form-control__label {
  font-size: 1em;
  line-height: 1;
  color: #999;
  transition: 200ms ease-out;
  transition-property: transform, color;
}

.andes-form-control__label-fixed {
  transform: scale(0.77778) translateY(-135%) !important;
}

.andes-form-control__field {
  font-size: inherit;
  font-family: inherit;
  color: #333;
  line-height: 1;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0.44444em 0 0.33333em;
  resize: none;
  overflow: hidden;
}

.andes-form-control---validated-icon {
  right: 0;
  opacity: 0;
  position: absolute;
  top: 1.5625em;
  animation: show-valid-ckeck 0.5s;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.andes-form-control__message {
  font-size: 0.77778em;
  line-height: 1em;
  color: #666;
  margin-top: 0.44444em;
  opacity: 0;
  transition: opacity 150ms ease-out;
}

.andes-form-control__border {
  position: relative;
  background-color: #ccc;
  top: -0.05556em;
  height: 1px;
  width: 100%;
}

.andes-form-control__border::after {
  content: "";
  position: absolute;
  height: 0.11111em;
  width: 100%;
  transition: transform 0.25s ease;
  transform: scaleX(0);
}

.andes-form-control:hover .andes-form-control__label {
  color: #666;
}

.andes-form-control:hover .andes-form-control__message {
  opacity: 1;
}

.andes-form-control:hover .andes-form-control__border::after {
  transform: scaleX(1);
  background-color: #666;
}

.andes-form-control__field:focus {
  outline: 0;
}

.andes-form-control__field:focus ~ .andes-form-control__message,
.andes-form-control--focused .andes-form-control__message {
  opacity: 1;
}

.andes-form-control__field:focus + .andes-form-control__border::after,
.andes-form-control--focused .andes-form-control__border::after {
  transform: scaleX(1);
  background-color: #3483fa !important;
}

.andes-form-control--focused .andes-form-control__label,
.andes-form-control--floated .andes-form-control__label {
  cursor: text;
  transform: scale(0.77778) !important;
}

.andes-form-control--invalid .andes-form-control__message {
  color: #f04449;
  font-weight: 600;
  opacity: 1;
}

.andes-form-control--invalid
  .andes-form-control__field:focus
  + .andes-form-control__border::after,
.andes-form-control--invalid
  .andes-form-control--focused
  .andes-form-control__border::after {
  transform: scaleX(1);
  background-color: #f04449 !important;
}

.andes-form-control--invalid .andes-form-control__border::after {
  transform: scaleX(1);
  background-color: #f04449 !important;
}

.andes-form-control--disabled .andes-form-control__field {
  color: #999;
}

.andes-form-control--disabled .andes-form-control__border {
  background-image: linear-gradient(
    to right,
    #ccc 0%,
    #ccc 50%,
    transparent 50%
  );
  background-position: 0 top;
  background-size: 6px 100%;
  background-repeat: repeat-x;
  background-color: transparent;
}

.andes-form-control--disabled .andes-form-control__border::after {
  display: none;
}

.andes-form-control__border--indeterminate {
  height: 0.11111em;
}

.andes-form-control__border--indeterminate::after {
  content: "";
  position: absolute;
  height: 0.11111em;
  background-color: #3483fa;
  animation: animation-indeterminate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform: scaleX(1);
}

.andes-form-control__border--validated {
  height: 0.11111em;
}

.andes-form-control__border--validated::after {
  content: "";
  position: absolute;
  height: 0.11111em;
  background-color: #3483fa;
  animation: animation-validated;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  transform: scaleX(1);
}

.andes-message {
  display: flexbox;
  display: flex;
  position: relative;
  font-family: "Proxima Nova", -apple-system, "Helvetica Neue", Helvetica,
    Roboto, Arial, sans-serif;
  font-size: 14px;
  border-radius: 4px;
  padding: 1.14286em 0;
  margin-top: 24px;
  margin-bottom: 24px;
  -webkit-font-smoothing: antialiased;
}

.andes-message--full-width {
  border-radius: 0;
}

.andes-message__text {
  color: #fff;
  font-weight: 400;
}

.andes-message__content {
  width: 85%;
}

.andes-message__text--neutral {
  color: #000;
}

.andes-message__title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.andes-message__border-color--neutral {
  background-color: #3483fa;
  border-radius: 6px 0 0 6px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.andes-message__border-color--warning {
  background-color: #f73;
  border-radius: 6px 0 0 6px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.andes-message.image {
  display: flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.andes-message__content.image {
  width: 72%;
}

.andes-message__content.image.untitled {
  display: flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.andes-message__image {
  background: #fff;
  border-radius: 50%;
  margin: 0 1.14286em;
  width: 3.42857em;
  height: 3.42857em;
}

.andes-message__icon {
  display: flexbox;
  display: flex;
  margin: 0 1.14286em;
  width: 1.14286em;
  height: 1.14286em;
}

.andes-message__close--error {
  width: 1em;
  height: 1em;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 1.14286em;
  transform: translateY(-50%);
}

.andes-message__close--error::after,
.andes-message__close--error::before {
  position: absolute;
  left: 0.5em;
  top: 0;
  content: "";
  height: 1em;
  width: 2px;
  background-color: #fff;
  cursor: pointer;
}

.andes-message__close--error::before {
  transform: rotate(45deg);
}

.andes-message__close--error::after {
  transform: rotate(-45deg);
}

.andes-message--error {
  background: #f23d4f;
}

.andes-message--error .andes-message__icon-inner {
  fill: #fff;
}

.andes-message--error .andes-message__icon-outer {
  stroke: #d12440;
  fill: #d12440;
}

.andes-message__close--warning {
  width: 1em;
  height: 1em;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 1.14286em;
  transform: translateY(-50%);
}

.andes-message__close--warning::after,
.andes-message__close--warning::before {
  position: absolute;
  left: 0.5em;
  top: 0;
  content: "";
  height: 1em;
  width: 2px;
  background-color: #fff;
  cursor: pointer;
}

.andes-message__close--warning::before {
  transform: rotate(45deg);
}

.andes-message__close--warning::after {
  transform: rotate(-45deg);
}

.andes-message--warning {
  background: #f73;
}

.andes-message--warning .andes-message__icon-inner {
  fill: #fff;
}

.andes-message--warning .andes-message__icon-outer {
  stroke: #e6540b;
  fill: #e6540b;
}

.andes-message__close--success {
  width: 1em;
  height: 1em;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 1.14286em;
  transform: translateY(-50%);
}

.andes-message__close--success::after,
.andes-message__close--success::before {
  position: absolute;
  left: 0.5em;
  top: 0;
  content: "";
  height: 1em;
  width: 2px;
  background-color: #fff;
  cursor: pointer;
}

.andes-message__close--success::before {
  transform: rotate(45deg);
}

.andes-message__close--success::after {
  transform: rotate(-45deg);
}

.andes-message--success {
  background: #00a650;
}

.andes-message--success .andes-message__icon-inner {
  fill: #fff;
}

.andes-message--success .andes-message__icon-outer {
  stroke: #008744;
  fill: #008744;
}

.andes-message__close--neutral {
  width: 1em;
  height: 1em;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 1.14286em;
  transform: translateY(-50%);
}

.andes-message__close--neutral::after,
.andes-message__close--neutral::before {
  position: absolute;
  left: 0.5em;
  top: 0;
  content: "";
  height: 1em;
  width: 2px;
  background-color: #fff;
  cursor: pointer;
}

.andes-message__close--neutral::before {
  transform: rotate(45deg);
}

.andes-message__close--neutral::after {
  transform: rotate(-45deg);
}

.andes-message--neutral {
  background: #3483fa;
}

.andes-message--neutral .andes-message__icon-inner {
  fill: #fff;
}

.andes-message--neutral .andes-message__icon-outer {
  stroke: #2968c8;
  fill: #2968c8;
}

.andes-message__close--quit {
  width: 1em;
  height: 1em;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 1.14286em;
  transform: translateY(-50%);
}

.andes-message__close--quit::after,
.andes-message__close--quit::before {
  position: absolute;
  left: 0.5em;
  top: 0;
  content: "";
  height: 1em;
  width: 2px;
  background-color: rgb(0 0 0 / 45%);
  cursor: pointer;
}

.andes-message__close--quit::before {
  transform: rotate(45deg);
}

.andes-message__close--quit::after {
  transform: rotate(-45deg);
}

.andes-message--neutral.andes-message--quit .andes-message__icon-outer {
  stroke: #3483fa;
  fill: #3483fa;
}

.andes-message--success.andes-message--quit .andes-message__icon-outer {
  stroke: #00a650;
  fill: #00a650;
}

.andes-message--warning.andes-message--quit .andes-message__icon-outer {
  stroke: #f73;
  fill: #f73;
}

.andes-message--error.andes-message--quit .andes-message__icon-outer {
  stroke: #f23d4f;
  fill: #f23d4f;
}

.andes-message--quiet {
  background: #eff0f3;
}

.andes-message--quiet--warning {
  border-left: 4px solid #f73;
}

.andes-message--quiet--neutral {
  border-left: 4px solid $andes-accent-color;
}

.andes-message--quit {
  background: rgb(0 0 0 / 7%);
}

.andes-message--quit .andes-message__title,
.andes-message--quit .andes-message__text {
  color: #000;
}

.andes-message--loud,
.andes-message__text--warning p {
  color: #fff;
}

.andes-message--quiet {
  color: rgb(0 0 0 / 4%);
}

.andes-message__title--quiet {
  color: #04204c;
}

.andes-message__text--quiet {
  color: #4d5763;
}

.andes-message__text--warning--quiet {
  color: rgb(0 0 0 / 80%);
}

.andes-message__title--warning--quiet {
  color: rgb(0 0 0 / 80%);
}

.andes-message--loud.andes-message__title--warning {
  font-size: 16px;
  font-weight: 600;
}

.andes-message--quiet.andes-message__title--warning {
  font-size: 14px;
  font-weight: normal;
}

.button-warning {
  background: #e6540b;
  border-color: #e6540b;
  font-weight: 600;
}

.message-button-container {
  margin-top: 16px;
  margin-bottom: 4px;
}

.andes-message__text ul {
  -webkit-padding-start: 16px;
  padding-inline-start: 16px;
  margin: 0;
  font-size: 14px;
}

.andes-message__text li {
  margin-bottom: 0.5em;
  font-size: 14px;
}

.andes-widther-support {
  width: 100%;
}

.andes-widther {
  display: flexbox;
  display: flex;
  width: 100%;
  max-width: 512px;
}

.andes-width--25 {
  width: 25%;
  max-width: initial;
}

.andes-width--50 {
  width: 50%;
  max-width: initial;
}

.andes-width--75 {
  width: 75%;
  max-width: initial;
}

.andes-width--100 {
  width: 100%;
  max-width: initial;
}

.andes-width--150 {
  width: 150%;
  max-width: initial;
}

.andes-width--200 {
  width: 200%;
  max-width: initial;
}

@keyframes animation-indeterminate {
  0% {
    left: 0;
    width: 5%;
  }

  50% {
    left: 15%;
    width: 60%;
  }

  100% {
    left: 101%;
    width: 0;
  }
}

@keyframes animation-validated {
  0% {
    left: 50%;
    width: 0%;
  }

  50% {
    left: 25%;
    width: 60%;
  }

  100% {
    left: 0%;
    width: 100%;
  }
}

@keyframes show-valid-ckeck {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.andes-form-control--textfield .andes-form-control__label {
  position: relative;
  top: 0;
  left: 0;
  transform: translateY(135%);
  transform-origin: left 50% 0;
  transition: 200ms ease-out;
  transition-property: transform, color;
  cursor: text;
}

.andes-form-control--multiline .andes-form-control__field {
  line-height: 1.3;
  min-height: 1.33333em;
}

.andes-form-control--textbox.andes-form-control--invalid
  .andes-form-control__field {
  border: 0.11111em solid #f04449;
}

.andes-form-control--textbox.andes-form-control--invalid
  .andes-form-control__field:hover:not(:focus) {
  border: 0.11111em solid #f04449;
}

.andes-form-control--textbox.andes-form-control--invalid
  .andes-form-control__field:focus {
  border: 0.11111em solid #f04449;
}

.andes-form-control--textbox.andes-form-control--disabled
  .andes-form-control__field {
  border: 0;
  background-color: #f2f2f2;
}

.andes-form-control--textbox.andes-form-control--disabled
  .andes-form-control__field:hover {
  border: 0;
}

.andes-form-control--textbox.andes-form-control--multiline
  .andes-form-control__field {
  min-height: 7.83333em;
}

.andes-form-control--textbox .andes-form-control__label {
  transform: translateY(190%);
  transform-origin: -15% 50% 0;
  padding-left: 0.625em;
  padding-top: 1px;
  pointer-events: none;
}

.andes-form-control--textbox .andes-form-control__field {
  border: 0.05556em solid #ccc;
  border-radius: 0.22222em;
  padding: 0.625em;
}

.andes-form-control--textbox .andes-form-control__field:hover {
  border: 0.11111em solid #666;
  margin-bottom: 0.22222em;
}

.andes-form-control--textbox .andes-form-control__field:focus {
  border: 0.11111em solid #3483fa;
  margin-bottom: 0.22222em;
}

.andes-form-control--textbox .andes-form-control__border {
  display: none;
}

.andes-form-control--countdown .andes-form-control__message,
.andes-form-control--countdown .andes-form-control__countdown {
  display: inline-block;
}

.andes-form-control--countdown .andes-form-control__message {
  width: 80%;
}

.andes-form-control--countdown .andes-form-control__countdown {
  width: 20%;
  text-align: right;
  font-size: 0.66667em;
  color: #999;
  line-height: 1;
  vertical-align: top;
  margin-top: 0.44444em;
  float: right;
}

.andes-form-control--sectioned .andes-form-control__control {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.andes-form-control--sectioned .andes-form-control__field {
  display: table-cell;
  width: 100%;
}

.andescontrol__field:input-placeholder {
  opacity: 1;
  color: #ccc;
  font-size: 18px;
}

.andes-form-control__field::placeholder {
  opacity: 1;
  color: #ccc;
  font-size: 18px;
}

.andescontrol__field::input-placeholder {
  color: #ccc;
  font-size: 18px;
}

.andescontrol__field::placeholder {
  opacity: 1;
  color: #ccc;
  font-size: 18px;
}

.andescontrol__field:placeholder {
  opacity: 1;
  color: #ccc;
  font-size: 18px;
}

.andescontrol__field:input-placeholder {
  color: #ccc;
  font-size: 18px;
}

.andescontrol__field::input-placeholder {
  color: #ccc;
  font-size: 18px;
}

.andescontrol__field:placeholder {
  color: #ccc;
  font-size: 18px;
}

.andes-form-control--focused .andes-form-control__prefix,
.andes-form-control--focused .andes-form-control__suffix {
  transition: opacity 0.2s ease-in;
}

.andes-form-control--focused
  .andes-form-control__prefix
  .andes-form-control__label {
  transform: translateY(120%) translateX(3em);
}

.andes-form-control--focused .andes-form-control__prefix span {
  overflow: hidden;
  display: block;
}

.andes-form-control__prefix,
.andes-form-control__suffix {
  color: #999;
  white-space: nowrap;
  display: table-cell;
  transition: opacity 0.2s ease-out;
  width: 2.22222em;
  opacity: 1;
  padding-right: 0.44444em;
}

.andes-form-control__suffix {
  padding-left: 0.44444em;
  text-align: right;
}

.andes-form-control__link {
  position: absolute;
  right: 0;
  top: 1em;
  padding: 0.625em;
  color: #105cf4;
  font-size: 0.75em;
  text-decoration: none;
}

.andes-form-control__link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  transition: opacity 0.2s ease-out;
  background: transparent;
  opacity: 0;
}

.andes-form-control__link:hover::before {
  transition: background-color 0.2s ease-in;
  opacity: 0.06;
  background: #3483fa;
}

.andes-widther-support {
  width: 100%;
}

.andes-widther {
  display: flexbox;
  display: flex;
  width: 100%;
  max-width: 512px;
}

.andes-width--25 {
  width: 25%;
  max-width: initial;
}

.andes-width--50 {
  width: 50%;
  max-width: initial;
}

.andes-width--75 {
  width: 75%;
  max-width: initial;
}

.andes-width--100 {
  width: 100%;
  max-width: initial;
}

.andes-width--150 {
  width: 150%;
  max-width: initial;
}

.andes-width--200 {
  width: 200%;
  max-width: initial;
}

.andes-dropdown {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  position: relative;
  -webkit-font-smoothing: antialiased;
  margin: 12px 0;
}

.andes-dropdown:focus {
  outline: none;
}

.andes-dropdown__popover {
  background-color: #fff;
  overflow: hidden;
  border-radius: 0.22222em;
  box-shadow: 0 0.1875em 0.25em 0 rgb(0 0 0 / 10%),
    0 0 0.0625em 0 rgb(0 0 0 / 25%);
  min-width: 12.5em;
  position: absolute;
  display: none;
  left: -1.6em;
}

.andes-dropdown--open .andes-dropdown__popover {
  display: block;
  z-index: 2;
}

.andes-dropdown__popover--checking-position {
  opacity: 0;
}

.andes-dropdown__field {
  position: relative;
}

.andes-dropdown__arrow {
  position: relative;
  display: inline-block;
  width: 0.66667em;
  height: 0.44444em;
  pointer-events: none;
  background: transparent;
  margin-left: 0.44444em;
}

.andes-dropdown__arrow::after {
  position: absolute;
  top: -0.11111em;
  left: 0.11111em;
  width: 0.33333em;
  height: 0.33333em;
  background: inherit;
  border-right: 0.11111em solid rgb(0 0 0 / 45%);
  border-bottom: 0.11111em solid rgb(0 0 0 / 45%);
  transform: rotate(45deg);
  transition: border-color 0.18s ease-out;
  content: "";
  box-sizing: content-box;
}

:focus ~ .andes-dropdown__arrow::after {
  border-color: #3483fa;
}

.andes-dropdown__trigger {
  cursor: pointer;
  color: rgb(0 0 0 / 80%);
  font: inherit;
  font-size: 1.125em;
  padding: 0.22222em 0.33333em;
  margin: 0;
  border: 0;
  background: none;
  border-radius: 0.22222em;
  transition: color 0.18s ease-out;
  outline: 0;
}

.andes-dropdown__trigger::-moz-focus-inner {
  border: 0;
}

.andes-dropdown__trigger:hover {
  color: #3483fa;
}

.andes-dropdown__trigger:hover .andes-dropdown__arrow::after {
  border-color: #3483fa;
}

.andes-dropdown__trigger:focus {
  box-shadow: 0 0 0 0.1875em rgb(52 131 250 / 30%);
}

.andes-dropdown--compact .andes-dropdown__trigger {
  font-size: 0.875em;
  padding: 0.42857em;
  border-radius: 0.28571em;
}

.andes-dropdown--compact .andes-dropdown__chevron {
  margin-left: 0.28571em;
  top: 0.07143em;
}

.andes-dropdown--compact .andes-dropdown__popover {
  padding: 0.57143em 0;
  border-radius: 0.28571em;
  min-width: 9.375em;
  left: -0.6em;
}

.andes-dropdown--compact .andes-list__item + .andes-list__item {
  border: 0;
}

.andes-dropdown.andes-dropdown--form {
  padding-top: 0.77778em;
}

.andes-dropdown.andes-dropdown--form:focus {
  outline: none;
}

.andes-dropdown.andes-dropdown--form.andes-form-control--error
  .andes-dropdown__arrow::after {
  border-color: #f04449;
}

.andes-dropdown.andes-dropdown--form.andes-form-control--error:hover
  .andes-dropdown__arrow::after {
  border-color: #f04449;
}

.andes-dropdown.andes-dropdown--form:hover .andes-dropdown__arrow::after {
  border-color: rgb(0 0 0 / 60%);
}

.andes-dropdown.andes-dropdown--form:hover .andes-dropdown__trigger {
  color: rgb(0 0 0 / 25%);
}

.andes-dropdown.andes-dropdown--form:hover
  .andes-dropdown__trigger--placeholder {
  color: rgb(0 0 0 / 25%);
}

.andes-dropdown.andes-dropdown--form:hover .andes-dropdown__trigger--filled {
  color: rgb(0 0 0 / 80%);
}

.andes-dropdown.andes-dropdown--form:hover
  .andes-dropdown__trigger--no-placeholder {
  color: rgb(0 0 0 / 45%);
}

.andes-dropdown.andes-dropdown--form:focus .andes-dropdown__arrow::after {
  border-color: #2c6bff;
}

.andes-dropdown.andes-dropdown--form:focus .andes-form-control__border::after {
  background-color: #2c6bff;
  transform: scaleX(1);
}

.andes-dropdown.andes-dropdown--form:focus.andes-form-control--error
  .andes-form-control__border::after {
  background-color: #f04449;
}

.andes-dropdown.andes-dropdown--form:focus.andes-form-control--error:focus
  .andes-dropdown__arrow::after {
  border-color: #f04449;
}

.andes-dropdown.andes-dropdown--form.andes-form-control--disabled
  .andes-dropdown__trigger,
.andes-dropdown.andes-dropdown--form
  .andes-form-control--disabled:hover
  .andes-dropdown__trigger {
  cursor: default;
  color: rgb(0 0 0 / 25%);
}

.andes-dropdown.andes-dropdown--form.andes-form-control--disabled
  .andes-dropdown__arrow::after,
.andes-dropdown.andes-dropdown--form
  .andes-form-control--disabled:hover
  .andes-dropdown__arrow::after {
  border-color: rgb(0 0 0 / 25%);
}

.andes-dropdown.andes-dropdown--form.andes-form-control--disabled
  .andes-dropdown__trigger--no-placeholder,
.andes-dropdown.andes-dropdown--form
  .andes-form-control--disabled:hover
  .andes-dropdown__trigger--no-placeholder {
  color: rgb(0 0 0 / 25%);
}

.andes-dropdown.andes-dropdown--form .andes-form-control__label {
  font-size: 0.77778em;
  position: absolute;
  top: 0;
}

.andes-dropdown.andes-dropdown--form .andes-dropdown__arrow {
  position: absolute;
  bottom: 4px;
  right: 0;
  z-index: 1;
}

.andes-dropdown.andes-dropdown--form .andes-dropdown__popover {
  left: -0.88889em;
  top: 0.77778em;
  padding: 12px 0;
}

.andes-dropdown.andes-dropdown--form .andes-dropdown__trigger {
  position: relative;
  margin-top: 0.44444em;
  margin-bottom: 6px;
  padding: 0;
  width: 100%;
  font-size: 18px;
  text-align: left;
  color: rgb(0 0 0 / 45%);
}

.andes-dropdown.andes-dropdown--form .andes-dropdown__trigger:focus {
  box-shadow: none;
}

.andes-dropdown.andes-dropdown--form .andes-dropdown__trigger--placeholder {
  color: rgb(0 0 0 / 25%);
}

.andes-dropdown.andes-dropdown--form .andes-dropdown__trigger--no-placeholder {
  color: rgb(0 0 0 / 25%);
}

.andes-dropdown.andes-dropdown--form .andes-dropdown__trigger--filled {
  color: rgb(0 0 0 / 80%);
}

.andes-dropdown.andes-dropdown--form .andes-dropdown__popover {
  width: calc(100% + 2em);
}

.andes-dropdown.andes-dropdown--form
  .andes-dropdown__popover
  .andes-list__item {
  padding: 0.72222em 0.88889em;
  font-size: 18px;
  border: 0;
}

.andes-dropdown.andes-dropdown--form
  .andes-dropdown__popover
  .andes-list__group--border {
  border-top: 1px solid #e6e6e6;
  margin: 12px 0;
}

.andes-dropdown.andes-dropdown--form
  .andes-dropdown__popover
  .andes-list__group--title {
  padding: 0.44444em 0.88889em;
}

.andes-dropdown.andes-dropdown--form
  .andes-dropdown__popover
  .andes-list__group--title
  .andes-list__item-text {
  font-size: 14px;
  color: rgb(0 0 0 / 45%);
}

.andes-dropdown.andes-dropdown--form .andes-dropdown-item-hover {
  background-color: #ededed;
}

.andes-dropdown.andes-dropdown--form .andes-form-control__field {
  margin: 8px 0 6px;
}

.andes-dropdown.andes-dropdown--form .andes-form-control__field option {
  padding: 0;
}

.andes-dropdown.andes-dropdown--form
  .andes-form-control__field::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.andes-dropdown.andes-dropdown--form .andes-form-control__field:focus {
  outline: 0;
}

.andes-dropdown.andes-dropdown--form .andes-form-control__field:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 rgb(0 0 0 / 80%);
}

.andes-list {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.125em;
  font-weight: 400;
  line-height: 1;
  background-color: #fff;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

.andes-list__item {
  padding: 0.72222em 0.88889em;
  display: flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 0 !important;
}

.andes-list__item + .andes-list__item {
  border-top: 0.0625em solid #e6e6e6;
}

.andes-list__item-with-image {
  padding: 0.72222em 0.88889em;
}

.andes-list__item-with-secondary .andes-list__item-tertiary {
  -ms-flex-item-align: auto;
  grid-row-align: auto;
  align-self: auto;
}

.andes-list__item-primary {
  color: rgb(0 0 0 / 80%);
}

.andes-list__item-secondary {
  color: rgb(0 0 0 / 45%);
  font-size: 16px;
  line-height: 1.25em;
}

.andes-list__item-image-container {
  display: flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.andes-list__item-tertiary {
  color: rgb(0 0 0 / 80%);
  -ms-flex-item-align: center;
  grid-row-align: center;
  align-self: center;
}

.andes-list__item-image {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  margin-right: 1.14286em;
  border: 1px solid #e6e6e6;
  display: block;
}

.andes-list__item-primary + .andes-list__item-secondary {
  margin-top: 8px;
}

.andes-list__group {
  padding: 0;
}

.andes-list__group .andes-list__group--header {
  display: flexbox;
  display: flex;
  font-size: 0.77778em;
  background-color: #f5f5f5;
  cursor: default;
  padding: 0.92857em 2.28571em;
}

.andes-list__group .andes-list__group--header-label {
  color: rgb(0 0 0 / 45%);
}

.andes-list__item--disabled .andes-list__item-primary {
  color: rgb(0 0 0 / 25%);
}

.andes-list__item--disabled .andes-list__item-tertiary {
  color: rgb(0 0 0 / 25%);
}

.andes-list__item--disabled .andes-list__item-secondary {
  color: rgb(0 0 0 / 25%);
}

.andes-list__item--disabled:hover {
  background: transparent;
}

.andes-list__item--selected {
  position: relative;
}

.andes-list__item--selected::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 0.22222em solid #3483fa;
}

.andes-list--selectable .andes-list__item {
  cursor: pointer;
}

.andes-list--selectable .andes-list__item:hover,
.andes-list--selectable .andes-list__item:focus {
  background-color: rgb(0 0 0 / 4%);
  transition: background-color 0.1s ease-out;
}

.andes-list--selectable .andes-list__item--disabled {
  cursor: default;
}

.andes-list--selectable .andes-list__item--disabled:hover,
.andes-list--selectable .andes-list__item--disabled:focus {
  background-color: #fff;
}

.andes-list--size-compact {
  font-size: 0.875em;
}

.andes-list--size-compact .andes-list__item {
  padding: 12px 1.14286em;
}

.andes-list--size-compact .andes-list__item-secondary {
  font-size: inherit;
}

.andes-list--dropdown.andes-list--size-compact .andes-list__item-secondary {
  display: none;
}

.andes-list--dropdown.andes-list--size-compact .andes-list__item-tertiary {
  display: none;
}

@supports (
  (-webkit-appearance: none) or (-moz-appearance: none) or (appearance: none)
) {
  .andes-dropdown--form .andes-form-control__field {
    appearance: none;
    background: none;
  }

  .andes-dropdown--form .andes-dropdown__arrow {
    background: inherit;
  }
}

.andes-widther-support {
  width: 100%;
}

.andes-widther {
  display: flexbox;
  display: flex;
  width: 100%;
  max-width: 512px;
}

.andes-width--25 {
  width: 25%;
  max-width: initial;
}

.andes-width--50 {
  width: 50%;
  max-width: initial;
}

.andes-width--75 {
  width: 75%;
  max-width: initial;
}

.andes-width--100 {
  width: 100%;
  max-width: initial;
}

.andes-width--150 {
  width: 150%;
  max-width: initial;
}

.andes-width--200 {
  width: 200%;
  max-width: initial;
}

.andes-widther-support {
  width: 100%;
}

.andes-widther {
  display: flexbox;
  display: flex;
  width: 100%;
  max-width: 512px;
}

.andes-width--25 {
  width: 25%;
  max-width: initial;
}

.andes-width--50 {
  width: 50%;
  max-width: initial;
}

.andes-width--75 {
  width: 75%;
  max-width: initial;
}

.andes-width--100 {
  width: 100%;
  max-width: initial;
}

.andes-width--150 {
  width: 150%;
  max-width: initial;
}

.andes-width--200 {
  width: 200%;
  max-width: initial;
}

.andes-checkbox {
  position: relative;
  cursor: pointer;
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.andes-checkbox__mimic {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.andes-checkbox__mimic::before {
  width: 18px;
  height: 18px;
  line-height: 1;
  position: absolute;
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cpath fill="%23ccc" fill-rule="nonzero" d="M2 1a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm0-1h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/%3E%3Cpath fill="%23fff" d="M2 1a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2z"/%3E%3C/g%3E%3C/svg%3E');
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.andes-checkbox__mimic::after {
  width: 18px;
  height: 18px;
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cpath fill="%23ccc" fill-rule="nonzero" d="M2 1a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm0-1h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/%3E%3Cpath fill="%23fff" d="M2 1a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2z"/%3E%3C/g%3E%3C/svg%3E');
  position: absolute;
  left: -0.1875em;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.2s ease-out;
  border-radius: 0.3125em;
  border: 0.1875em solid #3483fa;
  background: #fff;
  opacity: 0;
}

.andes-checkbox__input {
  position: absolute;
  top: 0;
  opacity: 0;
}

.andes-checkbox__input:hover ~ .andes-checkbox__mimic::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"%3E%3Cpath fill="%23999" fill-rule="nonzero" d="M2 2v14h14V2H2zm0-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/%3E%3C/svg%3E');
}

.andes-checkbox__input:hover ~ .andes-checkbox__mimic::after {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"%3E%3Cpath fill="%23999" fill-rule="nonzero" d="M2 2v14h14V2H2zm0-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/%3E%3C/svg%3E');
}

.andes-checkbox__input[disabled] ~ .andes-checkbox__label {
  color: #999;
}

.andes-checkbox__input[disabled] ~ .andes-checkbox__mimic::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cpath fill="%23ccc" fill-rule="nonzero" d="M2 1a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm0-1h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/%3E%3Cpath fill="%23fff" d="M2 1a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2z"/%3E%3C/g%3E%3C/svg%3E');
}

.andes-checkbox__input:checked ~ .andes-checkbox__mimic::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cpath fill="%233483fa" d="M0 2.503A2.502 2.502 0 0 1 2.503 0h12.994A2.502 2.502 0 0 1 18 2.503v12.994A2.502 2.502 0 0 1 15.497 18H2.503A2.502 2.502 0 0 1 0 15.497V2.503z"/%3E%3Cpath fill="%23fff" d="M7.286 14L3 9.673l1.2-1.211 3.086 3.115L13.8 5 15 6.212z"/%3E%3C/g%3E%3C/svg%3E');
}

.andes-checkbox__input:checked ~ .andes-checkbox__mimic::after {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cpath fill="%233483fa" d="M0 2.503A2.502 2.502 0 0 1 2.503 0h12.994A2.502 2.502 0 0 1 18 2.503v12.994A2.502 2.502 0 0 1 15.497 18H2.503A2.502 2.502 0 0 1 0 15.497V2.503z"/%3E%3Cpath fill="%23fff" d="M7.286 14L3 9.673l1.2-1.211 3.086 3.115L13.8 5 15 6.212z"/%3E%3C/g%3E%3C/svg%3E');
}

.andes-checkbox__input:checked:hover ~ .andes-checkbox__mimic::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cpath fill="%231e6dff" d="M0 2.503A2.502 2.502 0 0 1 2.503 0h12.994A2.502 2.502 0 0 1 18 2.503v12.994A2.502 2.502 0 0 1 15.497 18H2.503A2.502 2.502 0 0 1 0 15.497V2.503z"/%3E%3Cpath fill="%23fff" d="M7.286 14L3 9.673l1.2-1.211 3.086 3.115L13.8 5 15 6.212z"/%3E%3C/g%3E%3C/svg%3E');
}

.andes-checkbox__input:checked[disabled] ~ .andes-checkbox__mimic::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 018 18"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cpath fill="%23ccc" d="M0 2.503A2.502 2.502 0 0 1 2.503 0h12.994A2.502 2.502 0 0 1 18 2.503v12.994A2.502 2.502 0 0 1 15.497 18H2.503A2.502 2.502 0 0 1 0 15.497V2.503z"/%3E%3Cpath fill="%23fff" d="M7.286 14L3 9.673l1.2-1.211 3.086 3.115L13.8 5 15 6.212z"/%3E%3C/g%3E%3C/svg%3E');
}

.andes-checkbox__input:focus ~ .andes-checkbox__mimic::after {
  opacity: 0.4;
}

.andes-checkbox__label {
  display: inline-block;
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: #333;
  font-weight: 400;
}

.andes-checkbox__label-text {
  padding-left: 1.66667em;
}

.andes-checkbox--embedded .andes-checkbox__mimic::before {
  width: 10px;
  height: 10px;
  top: 38%;
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"%3E%3Cpath fill="%233483fa" fill-rule="nonzero" d="M2 1a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm0-1h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/%3E%3C/svg%3E');
}

.andes-checkbox--embedded .andes-checkbox__label {
  font-size: 12px;
  color: #3483fa;
  padding-left: 1.16667em;
}

.andes-checkbox--embedded
  .andes-checkbox__input:checked
  ~ .andes-checkbox__mimic::before {
  width: 10px;
  height: 10px;
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"%3E%3Crect width="10" height="10" fill="%233483fa" rx="2"/%3E%3Cpath fill="%23fff" fill-rule="nonzero" d="M7.47 2.47l1.06 1.06L4 8.06 1.47 5.53l1.06-1.06L4 5.94z"/%3E%3C/svg%3E');
}

.andes-checkbox--embedded
  .andes-checkbox__input:checked
  ~ .andes-checkbox__mimic::after {
  transition: opacity 100ms;
  opacity: 0.12;
}

.andes-checkbox--embedded
  .andes-checkbox__input:checked:hover
  ~ .andes-checkbox__mimic::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"%3E%3Crect width="10" height="10" fill="%233483fa" rx="2"/%3E%3Cpath fill="%23fff" fill-rule="nonzero" d="M7.47 2.47l1.06 1.06L4 8.06 1.47 5.53l1.06-1.06L4 5.94z"/%3E%3C/svg%3E');
}

.andes-checkbox--embedded
  .andes-checkbox__input:checked:hover
  ~ .andes-checkbox__mimic::after {
  transform: translateY(-50%) scale(1);
}

.andes-checkbox--embedded
  .andes-checkbox__input:checked[disabled]
  ~ .andes-checkbox__mimic::before {
  width: 10px;
  height: 10px;
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"%3E%3Crect width="10" height="10" fill="%23ccc" rx="2"/%3E%3Cpath fill="%23fff" fill-rule="nonzero" d="M7.47 2.47l1.06 1.06L4 8.06 1.47 5.53l1.06-1.06L4 5.94z"/%3E%3C/svg%3E');
}

.andes-checkbox--embedded
  .andes-checkbox__input
  ~ .andes-checkbox__mimic::after {
  content: "";
  width: 100%;
  height: 2em;
  left: -0.5em;
  top: 50%;
  transition: opacity 0.2s ease-out;
  background: #3483fa;
  border: 0;
  padding: 0 0.5em;
  border-radius: 0;
  opacity: 0;
}

.andes-checkbox--embedded
  .andes-checkbox__input:hover
  ~ .andes-checkbox__mimic::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"%3E%3Cpath fill="%233483fa" fill-rule="nonzero" d="M2 1a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm0-1h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/%3E%3C/svg%3E');
}

.andes-checkbox--embedded
  .andes-checkbox__input:hover
  ~ .andes-checkbox__mimic::after {
  opacity: 0.06;
}

.andes-checkbox--embedded
  .andes-checkbox__input:focus
  ~ .andes-checkbox__mimic::after {
  transition: opacity 100ms;
  opacity: 0.12;
}

.andes-checkbox--embedded
  .andes-checkbox__input[disabled]
  ~ .andes-checkbox__mimic::before {
  width: 10px;
  height: 10px;
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"%3E%3Cpath fill="%23ccc" fill-rule="nonzero" d="M2 1a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm0-1h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/%3E%3C/svg%3E');
}

.andes-checkbox--embedded
  .andes-checkbox__input[disabled]
  ~ .andes-checkbox__mimic::after {
  display: none;
}

.andes-checkbox--label-before .andes-checkbox__label {
  padding-left: 0;
  padding-right: 1.66667em;
}

.andes-checkbox--label-before .andes-checkbox__mimic::before {
  left: auto;
  right: 0;
}

.andes-checkbox--label-before .andes-checkbox__mimic::after {
  left: auto;
  right: -0.1875em;
}

.andes-checkbox--indeterminate
  .andes-checkbox__input:checked
  ~ .andes-checkbox__mimic::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cpath fill="%233483fa" d="M0 2.503A2.502 2.502 0 0 1 2.503 0h12.994A2.502 2.502 0 0 1 18 2.503v12.994A2.502 2.502 0 0 1 15.497 18H2.503A2.502 2.502 0 0 1 0 15.497V2.503z"/%3E%3Cpath fill="%23fff" fill-rule="nonzero" d="M4 10V8h10v2z"/%3E%3C/g%3E%3C/svg%3E');
}

.andes-checkbox--indeterminate
  .andes-checkbox__input:checked:hover
  ~ .andes-checkbox__mimic::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cpath fill="%231e6dff" d="M0 2.503A2.502 2.502 0 0 1 2.503 0h12.994A2.502 2.502 0 0 1 18 2.503v12.994A2.502 2.502 0 0 1 15.497 18H2.503A2.502 2.502 0 0 1 0 15.497V2.503z"/%3E%3Cpath fill="%23fff" fill-rule="nonzero" d="M4 10V8h10v2z"/%3E%3C/g%3E%3C/svg%3E');
}

.andes-checkbox--indeterminate
  .andes-checkbox__input:checked
  ~ .andes-checkbox__mimic::after {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cpath fill="%233483fa" d="M0 2.503A2.502 2.502 0 0 1 2.503 0h12.994A2.502 2.502 0 0 1 18 2.503v12.994A2.502 2.502 0 0 1 15.497 18H2.503A2.502 2.502 0 0 1 0 15.497V2.503z"/%3E%3Cpath fill="%23fff" fill-rule="nonzero" d="M4 10V8h10v2z"/%3E%3C/g%3E%3C/svg%3E');
}

.andes-checkbox--indeterminate
  .andes-checkbox__input:checked[disabled]
  ~ .andes-checkbox__mimic::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cpath fill="%23ccc" d="M0 2.503A2.502 2.502 0 0 1 2.503 0h12.994A2.502 2.502 0 0 1 18 2.503v12.994A2.502 2.502 0 0 1 15.497 18H2.503A2.502 2.502 0 0 1 0 15.497V2.503z"/%3E%3Cpath fill="%23fff" fill-rule="nonzero" d="M4 10V8h10v2z"/%3E%3C/g%3E%3C/svg%3E');
}

.andes-checkbox--disabled {
  cursor: default;
}

.andes-widther-support {
  width: 100%;
}

.andes-widther {
  display: flexbox;
  display: flex;
  width: 100%;
  max-width: 512px;
}

.andes-width--25 {
  width: 25%;
  max-width: initial;
}

.andes-width--50 {
  width: 50%;
  max-width: initial;
}

.andes-width--75 {
  width: 75%;
  max-width: initial;
}

.andes-width--100 {
  width: 100%;
  max-width: initial;
}

.andes-width--150 {
  width: 150%;
  max-width: initial;
}

.andes-width--200 {
  width: 200%;
  max-width: initial;
}

.andes-widther-support {
  width: 100%;
}

.andes-widther {
  display: flexbox;
  display: flex;
  width: 100%;
  max-width: 512px;
}

.andes-width--25 {
  width: 25%;
  max-width: initial;
}

.andes-width--50 {
  width: 50%;
  max-width: initial;
}

.andes-width--75 {
  width: 75%;
  max-width: initial;
}

.andes-width--100 {
  width: 100%;
  max-width: initial;
}

.andes-width--150 {
  width: 150%;
  max-width: initial;
}

.andes-width--200 {
  width: 200%;
  max-width: initial;
}

.ui-congrats {
  text-align: center;
  padding: 0 !important;
}

.ui-congrats--success {
  background-color: #40c426;
}

.ui-congrats--error {
  background-color: #f9726f;
}

.ui-congrats--warning {
  background-color: #f57819;
}

.ui-congrats__title {
  color: #fff;
  margin-top: 1em;
}

.ui-congrats__subtitle {
  color: #fff;
  margin-top: 1em;
}

.ui-congrats__bottom {
  background-color: #fff;
  padding: 3.125em;
}

.ui-congrats {
  margin: 1.5em 0;
}

.ui-congrats__top {
  padding: 4em 3em 3em;
}

.ui-congrats {
  text-align: center;
  padding: 0 !important;
}

.ui-congrats--success {
  background-color: #40c426;
}

.ui-congrats--error {
  background-color: #f9726f;
}

.ui-congrats--warning {
  background-color: #f57819;
}

.ui-congrats__title {
  color: #fff;
  margin-top: 1em;
}

.ui-congrats__subtitle {
  color: #fff;
  margin-top: 1em;
}

.ui-congrats__bottom {
  background-color: #fff;
  padding: 3.125em;
}

.andes-widther-support {
  width: 100%;
}

.andes-widther {
  display: flexbox;
  display: flex;
  width: 100%;
  max-width: 512px;
}

.andes-width--25 {
  width: 25%;
  max-width: initial;
}

.andes-width--50 {
  width: 50%;
  max-width: initial;
}

.andes-width--75 {
  width: 75%;
  max-width: initial;
}

.andes-width--100 {
  width: 100%;
  max-width: initial;
}

.andes-width--150 {
  width: 150%;
  max-width: initial;
}

.andes-width--200 {
  width: 200%;
  max-width: initial;
}

.andes-spinner {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.andes-spinner--inline {
  position: relative;
}

.andes-spinner--inline .andes-spinner__container {
  position: absolute;
}

.andes-spinner--inline .andes-spinner__icon {
  margin: 0;
}

.andes-spinner--block {
  position: absolute;
  z-index: 60;
}

.andes-spinner--block .andes-spinner__container {
  z-index: 1021;
}

.andes-spinner--fullscreen {
  position: fixed;
  z-index: 1021;
}

.andes-spinner--fullscreen .andes-spinner__container {
  z-index: 1022;
}

.andes-spinner--block,
.andes-spinner--fullscreen,
.andes-spinner__mask {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.andes-spinner--block .andes-spinner__container,
.andes-spinner--fullscreen .andes-spinner__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.andes-spinner__container--xxsmall-notlabel {
  width: 0.75em;
  height: 0.75em;
}

.andes-spinner__container--xxsmall .andes-spinner__label {
  margin-right: 1.66667em;
  font-size: 0.75em;
}

.andes-spinner__container--xsmall-notlabel {
  width: 1.125em;
  height: 1.125em;
}

.andes-spinner__container--xsmall .andes-spinner__label {
  margin-right: 2.14286em;
  font-size: 0.875em;
}

.andes-spinner__container--small-notlabel {
  width: 2em;
  height: 2em;
}

.andes-spinner__container--small .andes-spinner__label {
  display: block;
  margin-top: 3.42857em;
  min-width: 2em;
  font-size: 0.875em;
  line-height: 1;
  text-align: center;
}

.andes-spinner__container--medium-notlabel {
  width: 3em;
  height: 3em;
}

.andes-spinner__container--medium .andes-spinner__label {
  display: block;
  margin-top: 4.125em;
  min-width: 3em;
  line-height: 1;
  text-align: center;
}

.andes-spinner__container--large-notlabel {
  width: 4em;
  height: 4em;
}

.andes-spinner__container--large .andes-spinner__label {
  display: block;
  margin-top: 4.88889em;
  min-width: 4em;
  font-size: 1.125em;
  text-align: center;
}

.andes-spinner__mask {
  position: absolute;
  background-color: rgb(255 255 255 / 10%) !important;
}

.andes-spinner__mask--highlight {
  background-color: rgb(52 131 250 / 10%) !important;
}

.andes-spinner--inline .andes-spinner__mask {
  display: none;
}

.andes-spinner__icon {
  width: 2em;
  height: 2em;
  position: absolute;
  inset: 0;
  margin: 0 auto;
  animation: rotate-all 1s linear infinite;
}

.andes-spinner__icon--xxsmall {
  width: 0.75em;
  height: 0.75em;
  left: auto;
  margin: auto;
}

.andes-spinner__icon--xsmall {
  width: 1.125em;
  height: 1.125em;
  left: auto;
  margin: auto;
}

.andes-spinner__icon--small {
  width: 2em;
  height: 2em;
}

.andes-spinner__icon--medium {
  width: 3em;
  height: 3em;
}

.andes-spinner__icon--large {
  width: 4em;
  height: 4em;
}

.andes-spinner__icon-right,
.andes-spinner__icon-left {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.andes-spinner__icon-left {
  left: 0;
}

.andes-spinner__icon-right {
  right: 0;
}

.andes-spinner__icon-border {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 200px 0 0 200px;
  border: 2px solid $andes-accent-color;
  box-sizing: border-box;
}

.andes-spinner__icon-right .andes-spinner__icon-border {
  border-radius: 0 200px 200px 0;
  border-left: 0;
  transform: rotate(-10deg);
  transform-origin: left center;
  animation: rotate-right 0.75s linear infinite alternate;
}

.andes-spinner__icon-right .andes-spinner__icon-border::after {
  bottom: -10px;
  left: -5px;
}

.andes-spinner__icon-left .andes-spinner__icon-border {
  border-right: 0;
  transform: rotate(10deg);
  transform-origin: right center;
  animation: rotate-left 0.75s linear infinite alternate;
}

.andes-spinner__icon-left .andes-spinner__icon-border::after {
  bottom: -10px;
  right: -5px;
}

.andes-spinner--highlight .andes-spinner__label {
  color: #fff;
}

.andes-spinner--highlight .andes-spinner__icon-border {
  border-color: #fff;
}

@keyframes rotate-left {
  to {
    transform: rotate(30deg);
  }

  from {
    transform: rotate(175deg);
  }
}

@keyframes rotate-right {
  from {
    transform: rotate(-175deg);
  }

  to {
    transform: rotate(-30deg);
  }
}

@keyframes rotate-all {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

.andes-widther-support {
  width: 100%;
}

.andes-widther {
  display: flexbox;
  display: flex;
  width: 100%;
  max-width: 512px;
}

.andes-width--25 {
  width: 25%;
  max-width: initial;
}

.andes-width--50 {
  width: 50%;
  max-width: initial;
}

.andes-width--75 {
  width: 75%;
  max-width: initial;
}

.andes-width--100 {
  width: 100%;
  max-width: initial;
}

.andes-width--150 {
  width: 150%;
  max-width: initial;
}

.andes-width--200 {
  width: 200%;
  max-width: initial;
}

.andes-modal-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1020;
  background-color: rgb(0 0 0 / 60%);
  opacity: 1;
  animation: fadeIn 0.2s ease-in-out;
  will-change: opacity;
}

.andes-modal-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  z-index: 1021;
  box-shadow: 0 0 1px 1px rgb(0 0 0 / 7%), 0 20px 25px 0 rgb(0 0 0 / 10%);
  border-radius: 0.25em;
  overflow: hidden;
  background: #fff;
  transform: translate(-50%, -50%);
  opacity: 1;
  animation: translateIn 0.2s ease-in-out, fadeIn 0.2s ease-in-out;
  will-change: opacity, transform;
}

.andes-modal-dialog--closing {
  animation: translateOut 0.2s ease-in-out, fadeOut 0.2s ease-in-out;
  transform: translate(-50%, calc(-50% + 20px));
  opacity: 0;
}

.andes-modal-bg--closing {
  animation: fadeOut 0.2s ease-in-out;
  opacity: 0;
}

.andes-modal-dialog__button-close {
  position: absolute;
  z-index: 1022;
}

.andes-modal-dialog__button-close::after,
.andes-modal-dialog__button-close::before {
  opacity: 1;
}

.andes-modal-dialog__button-close:hover::before,
.andes-modal-dialog__button-close:hover::after {
  background-color: #1e6dff;
}

.andes-modal-dialog__container {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: absolute;
  -webkit-overflow-scrolling: touch;
  box-sizing: content-box;
}

.andes-modal-dialog__content {
  color: rgb(0 0 0 / 45%);
  -webkit-font-smoothing: antialiased;
}

.andes-modal-dialog--is-open {
  overflow: hidden;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes translateIn {
  from {
    transform: translate(-50%, calc(-50% + 20px));
  }

  to {
    transform: translate(-50%, -50%);
  }
}

@keyframes translateOut {
  from {
    transform: translate(-50%, -50%);
  }

  to {
    transform: translate(-50%, calc(-50% + 20px));
  }
}

.andes-modal-dialog__actions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1021;
  transition: box-shadow 0.2s ease-in-out;
  margin: 0 auto;
  background: #fff;
}

.andes-modal-dialog__actions--shadow {
  box-shadow: 0 0 1px 1px rgb(0 0 0 / 4%), 0 1px 2px 0 rgb(0 0 0 / 25%);
}

.andes-modal .andes-modal-dialog__header-title {
  font-weight: 600;
  line-height: 1.3;
  margin: 0;
}

.andes-modal .andes-modal-dialog__header ~ .andes-modal-dialog__content {
  padding-top: 0;
  padding-bottom: 0;
}

.andes-modal-dialog__header--scrolled {
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 7%), 0 0 24px 0 rgb(0 0 0 / 10%);
  position: absolute;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  z-index: 1021;
  animation: fadeIn 0.2s ease-in-out;
  opacity: 1;
}

.andes-modal-dialog__header--scrolled
  ~ .andes-modal-dialog__container
  .andes-modal-dialog__content {
  padding-top: 0;
  padding-bottom: 0;
}

.andes-modal-dialog__header--scrolled .andes-modal-dialog__header-title {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
}

.andes-modal--full .andes-modal-dialog {
  max-width: 100%;
  max-height: 100%;
  border-radius: 0;
}

.andes-modal--full .andes-modal-dialog__button-close {
  width: 1em;
  height: 1em;
  cursor: pointer;
  position: absolute;
  top: 2em;
  left: 2em;
}

.andes-modal--full .andes-modal-dialog__button-close::after,
.andes-modal--full .andes-modal-dialog__button-close::before {
  position: absolute;
  left: 0.5em;
  top: 0;
  content: "";
  height: 1em;
  width: 2px;
  background-color: #3483fa;
  cursor: pointer;
}

.andes-modal--full .andes-modal-dialog__button-close::before {
  transform: rotate(45deg);
}

.andes-modal--full .andes-modal-dialog__button-close::after {
  transform: rotate(-45deg);
}

.andes-modal--full .andes-modal-dialog__content {
  padding: 2em;
}

.andes-modal--full .andes-modal-dialog__header {
  padding: 80px 2em 2em;
}

.andes-modal--full .andes-modal-dialog__header-title {
  font-size: 2em;
}

.andes-modal--full .andes-modal-dialog__actions {
  padding: 1.5em;
  width: auto;
}

.andes-modal--full .andes-modal-dialog__header--scrolled {
  padding: 1.5em 4em;
  text-align: center;
}

.andes-modal--full
  .andes-modal-dialog__header--scrolled
  .andes-modal-dialog__header-title {
  font-size: 1.25em;
}

.andes-modal--full
  .andes-modal-dialog__header--scrolled
  .andes-modal-dialog__button-close {
  top: 1.8em;
  margin-top: 0;
}

.andes-modal--light .andes-modal-dialog {
  max-height: 22.375em;
  max-width: calc(100% - 64px);
  text-align: center;
}

.andes-modal--light .andes-modal-dialog__button-close {
  position: relative;
  width: 1em;
  height: 1em;
  cursor: pointer;
  top: 2em;
  left: 2em;
}

.andes-modal--light .andes-modal-dialog__button-close::after,
.andes-modal--light .andes-modal-dialog__button-close::before {
  position: absolute;
  left: 0.5em;
  top: 0;
  content: "";
  height: 1em;
  width: 2px;
  background-color: #fff;
  cursor: pointer;
}

.andes-modal--light .andes-modal-dialog__button-close::before {
  transform: rotate(45deg);
}

.andes-modal--light .andes-modal-dialog__button-close::after {
  transform: rotate(-45deg);
}

.andes-modal--light .andes-modal-dialog__content {
  padding: 1.5em;
}

.andes-modal--light .andes-modal-dialog__header {
  padding: 1.5em;
}

.andes-modal--light .andes-modal-dialog__header-title {
  font-size: 1.25em;
}

.andes-modal--light .andes-modal-dialog__actions {
  width: calc(100% - 64px);
  background: transparent;
  text-align: center;
  bottom: 2em;
}

.andes-modal--light .andes-modal-dialog__actions .andes-button--filled {
  display: none;
}

.andes-modal--light
  .andes-modal-dialog--scrolled
  .andes-modal-dialog__header-title {
  font-size: 1.25em;
}

.andes-modal--light
  .andes-modal-dialog--scrolled
  .andes-modal-dialog__button-close {
  top: 1.875em;
  margin-top: 0;
}

@media (width >= 768px) {
  .andes-modal-dialog {
    max-width: 40em;
    max-height: 30em;
  }

  .andes-modal--loose .andes-modal-dialog__button-close {
    width: 1em;
    height: 1em;
    cursor: pointer;
    position: absolute;
    left: auto;
    right: 4em;
    top: 4em;
    padding: 0;
    margin-top: 14px;
  }

  .andes-modal--loose .andes-modal-dialog__button-close::after,
  .andes-modal--loose .andes-modal-dialog__button-close::before {
    position: absolute;
    left: 0.5em;
    top: 0;
    content: "";
    height: 1em;
    width: 2px;
    background-color: #3483fa;
    cursor: pointer;
  }

  .andes-modal--loose .andes-modal-dialog__button-close::before {
    transform: rotate(45deg);
  }

  .andes-modal--loose .andes-modal-dialog__button-close::after {
    transform: rotate(-45deg);
  }

  .andes-modal--loose .andes-modal-dialog__content {
    padding: 4em;
  }
}

@media (width >= 768px) {
  .andes-modal--loose .andes-modal-dialog__header-title {
    font-size: 2em;
  }

  .andes-modal--loose .andes-modal-dialog__header {
    padding: 4em 4em 2em;
    margin-right: 2em;
  }
}

@media (width >= 768px) {
  .andes-modal--loose .andes-modal-dialog__actions {
    padding: 1.5em 4em;
    bottom: 0;
    width: 100%;
  }
}

@media (width >= 768px) {
  .andes-modal--loose .andes-modal-dialog__header--scrolled {
    padding: 1.5em 4em;
  }

  .andes-modal--loose
    .andes-modal-dialog__header--scrolled
    .andes-modal-dialog__button-close {
    top: 1.5em;
    margin-top: 10px;
  }

  .andes-modal--loose
    .andes-modal-dialog__header--scrolled
    .andes-modal-dialog__header-title {
    font-size: 1.5em;
  }
}

@media (width >= 768px) {
  .andes-modal--tight .andes-modal-dialog {
    max-width: 40em;
    max-height: 30em;
  }

  .andes-modal--tight .andes-modal-dialog__button-close {
    width: 1em;
    height: 1em;
    cursor: pointer;
    position: absolute;
    right: 2em;
    top: 2em;
    margin-top: 10px;
  }

  .andes-modal--tight .andes-modal-dialog__button-close::after,
  .andes-modal--tight .andes-modal-dialog__button-close::before {
    position: absolute;
    left: 0.5em;
    top: 0;
    content: "";
    height: 1em;
    width: 2px;
    background-color: #3483fa;
    cursor: pointer;
  }

  .andes-modal--tight .andes-modal-dialog__button-close::before {
    transform: rotate(45deg);
  }

  .andes-modal--tight .andes-modal-dialog__button-close::after {
    transform: rotate(-45deg);
  }

  .andes-modal--tight .andes-modal-dialog__content {
    padding: 2em;
  }
}

@media (width >= 768px) {
  .andes-modal--tight .andes-modal-dialog__header {
    padding: 2em;
    padding-right: 68px;
  }

  .andes-modal--tight .andes-modal-dialog__header-title {
    font-size: 1.5em;
  }
}

@media (width >= 768px) {
  .andes-modal--tight .andes-modal-dialog__actions {
    padding: 1.5em 2em;
  }
}

@media (width >= 768px) {
  .andes-modal--tight .andes-modal-dialog__header--scrolled {
    padding: 1.5em 2em;
  }

  .andes-modal--tight
    .andes-modal-dialog__header--scrolled
    .andes-modal-dialog__button-close {
    top: 1.5em;
    margin-top: 10px;
  }
}

.andes-widther-support {
  width: 100%;
}

.andes-widther {
  display: flexbox;
  display: flex;
  width: 100%;
  max-width: 512px;
}

.andes-width--25 {
  width: 25%;
  max-width: initial;
}

.andes-width--50 {
  width: 50%;
  max-width: initial;
}

.andes-width--75 {
  width: 75%;
  max-width: initial;
}

.andes-width--100 {
  width: 100%;
  max-width: initial;
}

.andes-width--150 {
  width: 150%;
  max-width: initial;
}

.andes-width--200 {
  width: 200%;
  max-width: initial;
}

.andes-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  padding: 0;
  border: 1px solid #ededed;
  color: rgb(0 0 0 / 80%);
  font-size: 0.875em;
  font-weight: 400;
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.andes-table__header {
  background-color: #f5f5f5;
  font-weight: 600;
}

.andes-table__column {
  border-bottom: 1px solid #ededed;
  padding: 16px 24px;
  white-space: nowrap;
}

.andes-table__row {
  border-bottom: 16px solid #e6e6e6;
}

.andes-table__row:last-of-type {
  border: 0;
}

.andes-table__body,
.andes-table__row,
.andes-table__header,
.andes-table__column {
  display: block;
}

.andes-table__link {
  color: #3483fa;
}

.andes-table__head {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.andes-table__column::before {
  content: attr(data-title);
  float: left;
  background-color: #f5f5f5;
  font-weight: 600;
  padding: 16px 24px;
  width: 150px;
  margin: -16px 24px 0 -24px;
}

@media (width >= 768px) {
  .andes-table {
    width: 100.1%;
  }

  .andes-table__head {
    position: relative;
    clip-path: none;
    height: auto;
    width: auto;
    overflow: auto;
  }

  .andes-table__row {
    display: table-row;
  }

  .andes-table__row:hover {
    background-color: #ededed;
  }

  .andes-table__header,
  .andes-table__column {
    vertical-align: middle;
    display: table-cell;
    text-align: left;
    padding: 15px 16px;
    white-space: normal;
  }

  .andes-table__header:first-of-type,
  .andes-table__column:first-of-type {
    padding: 15px 24px;
  }

  .andes-table__header:last-of-type,
  .andes-table__column:last-of-type {
    padding: 15px 24px 15px 16px;
  }

  .andes-table__header::before,
  .andes-table__column::before {
    content: "";
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .andes-table__header-checkbox,
  .andes-table .andes-table__column-checkbox {
    width: 18px;
  }

  .andes-table__header--center,
  .andes-table__column--center {
    text-align: center;
  }

  .andes-table__header--right,
  .andes-table__column--right {
    text-align: right;
  }

  .andes-table__row {
    display: table-row;
    border: 0;
  }

  .andes-table__body {
    display: table-row-group;
  }
}

@media (width >= 768px) {
  h1 {
    font-size: 2.5em;
    margin: 1.4em 0 0.6em;
  }

  .layout {
    min-height: 100vh;
  }

  .layout__content {
    padding: 2em 1.5em 20px;
  }

  .slideout-open .layout__dimmer {
    z-index: 60;
  }

  .layout__content__home {
    padding: 0;
  }

  .layout__content-sdk {
    padding: em(0) em(0) em(0);
  }

  .cards-form:last-child:nth-child(odd) {
    width: 47.5%;
  }

  .navbar {
    z-index: 90;
    height: 4.0625em;
  }

  .navbar__container {
    display: flexbox;
    display: flex;
    vertical-align: middle;
  }

  .navbar__menu {
    display: inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-left: 3.3125em;
    padding: 10px;
  }

  .navbar__reference {
    display: block;
  }

  .navbar__support {
    display: block;
  }

  .navbar__menu-item {
    margin-right: 2em;
    color: #000;
    font-size: 15px;
    line-height: 1;
    display: inline-block;
    transform: scale(1);
    opacity: 1;
    transition: opacity 200ms;
    transition-delay: 180ms;
  }

  .navbar__menu-item.is-active {
    color: $andes-accent-color;
    fill: $andes-accent-color;
  }

  .navbar__user-btn {
    margin-right: 0;
  }

  .navbar__user-icon {
    height: 1.25em;
    width: auto;
  }

  .navbar__user-menu {
    right: 20px;
    top: 102%;
  }

  .navbar__menu-icon {
    margin-right: 6px;
  }

  .navbar__support:hover .navbar__menu-icon svg {
    fill: $andes-accent-color;
    color: $andes-accent-color;
  }

  .navbar__support:hover .navbar__menu-item {
    color: $andes-accent-color;
  }

  .navbar__reference:hover .navbar__menu-icon path {
    fill: $andes-accent-color;
    color: $andes-accent-color;
  }

  .navbar__reference:hover .navbar__menu-item {
    color: $andes-accent-color;
  }

  .navbar__search-btn {
    display: in;
  }

  .navbar__controls {
    display: inline-flexbox;
    display: inline-flex;
    -ms-flex-positive: initial;
    flex-grow: initial;
    margin-right: 0;
  }

  .navbar__user-nickname {
    display: none;
    font-size: 14px;
    line-height: 1.29;
    color: rgb(0 0 0 / 80%);
  }

  .user-menu-chevron {
    margin-bottom: 2.5px;
    margin-left: 8px;
  }

  .navbar__user-logged-btn {
    position: relative;
    margin-right: 20px;
  }

  .sidebar {
    width: 16.875em;
    z-index: 70;
  }

  .sidebar__menu {
    padding: 5.5625em 0;
  }

  .sidebar__menu a:hover,
  .sidebar__menu a:focus {
    background: #ddd;
    color: #333;
    text-decoration: none;
  }

  .sidebar__menu a.active:focus {
    color: $andes-accent-color;
  }

  .sidebar__header-menu {
    display: none;
  }

  table {
    display: table;
    max-width: 100%;
  }

  .c-404 {
    margin-top: 7.5em;
  }

  .c-404__img {
    margin-bottom: 1.5em;
  }

  .c-404__title {
    max-width: 17.875em;
  }

  .card-info,
  .card-warning {
    padding-left: 4.57143em;
  }

  .card,
  .home-detail-card-container,
  .card-dummy,
  blockquote {
    padding: 20px 40px 32px;
    margin: 32px 0;
  }

  .card-support {
    margin: 0.65em !important;
  }

  .card-info p:first-child,
  .card-warning p:first-child {
    margin-left: -1.85714em;
  }

  .card-highlight {
    margin: 0;
  }

  .card-highlight h3 {
    font-size: 20px;
    font-weight: 600;
    color: rgb(0 0 0 / 80%);
    margin-bottom: 1.09091em;
  }

  .card-highlight #tc-icon {
    position: relative;
    top: -0.75em;
  }

  .card-highlight #instore-icon {
    position: relative;
    top: -0.75em;
  }

  .card-highlight #plug-icon {
    position: relative;
    top: -0.75em;
  }

  .grid {
    display: flexbox;
    display: flex;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .grid__item,
  .card-dummy {
    margin-right: 1.25em;
    width: 33%;
  }

  .grid__item:active,
  .card-dummy:active {
    text-decoration: none;
  }

  .grid__item:focus,
  .card-dummy:focus {
    text-decoration: none;
  }

  .grid__item:last-child,
  .card-dummy:last-child {
    margin-right: 0;
  }

  .grid--50 .grid__item,
  .grid--50 .card-dummy {
    width: 50%;
  }

  .grid--33 .grid__item,
  .grid--33 .card-dummy {
    width: 33%;
  }

  .grid--25 .grid__item,
  .grid--25 .card-dummy {
    width: 25%;
  }

  .tooltip-content {
    visibility: visible;
    left: -75px;
  }

  .like-widget {
    background: #eff0f3;
    box-shadow: none;
    padding: 32px;
    display: none;
    margin-bottom: 0 !important;
  }

  .title__like {
    font-size: 18px;
    line-height: 1.56;
    color: #04204c;
  }

  .satisfaction-container {
    height: 40px;
  }

  .satisfaction-title-container {
    width: auto;
    float: left;
    margin-top: 8px !important;
  }

  .andes-button--small {
    display: inline-table !important;
  }

  .satisfaction-title-container.h2 {
    margin-top: 7px;
    font-size: 18px;
  }

  .satisfaction-button-container {
    float: left;
    margin-left: 40px;
    margin-top: 0;
  }

  .satisfaction-answer {
    font-size: 16px;
    margin-top: 24px;
    float: left;
    display: none;
  }

  .satisfaction-answer.p {
    float: left;
  }

  .home-container-first {
    width: 100%;
    max-width: 866px;
    margin: 0 auto;
    display: flow-root;
    margin-top: 48px;
    margin-bottom: 40px;
  }

  .home-title-container {
    width: 48%;
    float: left;
    margin-left: 26px;
    padding-left: 0;
    padding-right: 0;
  }

  .home-container-first h3 {
    font-size: 12px;
    font-weight: 600;
    line-height: 0.7;
    letter-spacing: 3px;
    color: rgb(0 0 0 / 80%);
    text-transform: uppercase;
  }

  .home-container-first h1 {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.15;
    color: rgb(0 0 0 / 80%);
    margin-top: 65px;
    margin-bottom: 20px;
  }

  .home-container-first p {
    font-size: 16px;
    line-height: 1.25;
    color: rgb(0 0 0 / 45%);
  }

  .home-image-container {
    width: 44%;
    margin-right: 26px;
    float: right;
    text-align: right;
  }

  .home-image-container svg {
    padding-right: 0;
    padding-left: 8px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .home-card:hover {
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 10%);
  }

  .home-card p {
    font-size: 14px;
    line-height: 1.29;
    color: rgb(0 0 0 / 80%);
  }

  .home-first-partial {
    max-width: 50.875em;
    margin: 0 auto;
    padding: 0 0 106px;
  }

  .home-card-partial {
    max-width: 866px;
    margin: 40px auto;
    padding-right: 0;
    padding-left: 0;
  }

  .payments-card-link:first-child,
  .payments-card-link-second:first-child {
    margin-left: 26px;
  }

  .payments-card-link:nth-child(3),
  .payments-card-link-second:nth-child(3) {
    margin-right: 26px;
  }

  .payments-card-link,
  .payments-card-link-second {
    display: flexbox;
    display: flex;
  }

  .home-banner-container {
    margin-top: 32px;
    margin-bottom: 48px;
    background-color: $andes-accent-color;
    width: 100%;
    height: auto;
    overflow: hidden;
  }

  .home-banner-content {
    max-width: 866px;
    margin: 0 auto;
  }

  .imagen-banner-container {
    width: 40%;
    max-height: 192px;
    margin-left: 26px;
  }

  .home-banner-title {
    width: 60%;
    height: auto;
    margin-right: 26px;
  }

  .home-banner-title h3 {
    font-size: 10px;
    font-weight: 600;
    line-height: 1.06;
    letter-spacing: 0.5px;
    color: #fff;
    margin-bottom: 12px;
    margin-top: 24px;
  }

  .home-banner-title h2 {
    font-size: 20px;
    max-width: 360px;
    font-weight: bold;
    line-height: 1.2;
    color: #fff;
    margin-top: 0;
    margin-bottom: 24px;
  }

  .home-banner-button {
    border-radius: 4px;
    height: 24px;
    background-color: #007eb5;
    border: hidden;
    width: fit-content;
    display: flexbox;
    display: flex;
    margin-bottom: 16px;
  }

  .home-banner-button:hover {
    border-radius: 4px;
    height: 24px;
    background-color: #005e88;
    border: hidden;
    display: flexbox;
    display: flex;
    margin-top: 22px;
    transition: background-color 0.2s ease-in;
    cursor: pointer;
  }

  .title-banner-button {
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    margin: 5px 8px;
    text-align: center;
    color: #fff;
  }

  .home-banner-link {
    display: none;
  }

  .imagen-banner-container-mobile {
    display: none;
  }

  .imagen-banner-container {
    display: block;
    margin-top: 40px;
  }

  .home-footer-cards-container {
    max-width: 866px;
    margin: 0 auto;
    margin-bottom: 48px;
  }

  .card-middle {
    border-right: solid 1px #e2e2e4;
    border-left: solid 1px #e2e2e4;
    border-top: none;
    border-bottom: none;
  }

  .home-footer-card {
    padding: 8px 30px 8px 45px;
  }

  .home-footer-card:first-child {
    margin-left: 26px;
  }

  .home-footer-card:nth-child(3) {
    margin-right: 26px;
  }

  .h4-title-card {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    color: rgb(0 0 0 / 80%);
  }

  .home-footer-card a {
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    color: $andes-accent-color;
  }

  .footer-icon-home {
    margin-top: 18px;
  }

  .home-detail-title-container {
    max-width: 866px;
    margin: 0 auto;
    margin-bottom: 54px;
  }

  .home-detail-title-container h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.25;
    color: rgb(0 0 0 / 80%);
    margin-top: 40px;
    margin-left: 26px;
    margin-right: 26px;
  }

  .home-detail-title-container p {
    font-size: 16px;
    line-height: 1.25;
    color: rgb(0 0 0 / 80%);
    margin-top: 8px;
    margin-left: 26px;
    margin-right: 26px;
  }

  .home-detail-back-container {
    display: flexbox;
    display: flex;
    margin: 0 auto;
    max-width: 866px;
    margin-top: 33px;
  }

  .home-detail-card-container {
    padding: 24px;
  }

  .home-detail-card-container:hover {
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 10%);
  }

  .home-detail-card {
    margin-top: 54px;
    display: flexbox;
    display: flex;
    margin: 0 auto;
    max-width: 866px;
    padding-right: 0;
    padding-left: 0;
  }

  .home-detail-card:first-child {
    margin-left: 26px;
  }

  .home-detail-card-icon svg {
    display: block;
    width: auto;
  }

  .home-detail-card-highlight {
    text-align: left;
    font-size: 1em;
    margin: 0;
    margin-top: 0;
  }

  .home-detail-card-h3 {
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    margin-top: 16px;
    color: rgb(0 0 0 / 80%);
  }

  .home-detail-card-checkout {
    max-width: 150px;
  }

  .home-detail-card-p {
    font-size: 14px;
    line-height: 1.29;
    text-decoration: none;
    margin-top: 16px;
    color: rgb(0 0 0 / 80%);
  }

  .home-detail-second-title-container-h3 {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin: 40px 26px 24px;
  }

  .home-detail-second-title-container {
    margin: 0 auto;
    max-width: 866px;
  }

  .payments-card-link-second {
    display: flexbox;
    display: flex;
  }

  .home-detail-title-sdk {
    margin-bottom: 54px;
  }

  .homepl-detail-back-container {
    display: flexbox;
    display: flex;
    margin: 0 auto;
    max-width: 940px;
    margin-top: 33px;
  }

  .home-detail-back-image {
    margin-right: 12px;
    margin-left: 22px;
  }

  .homepl-detail-title-container {
    max-width: 940px;
    margin: 0 auto;
    margin-bottom: 48px;
  }

  .homepl-detail-title-container h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.25;
    color: rgb(0 0 0 / 80%);
    margin: 40px 26px 0;
    padding: 0;
  }

  .home-detail-title-h3 {
    font-size: 16px;
    line-height: 1.25;
    color: rgb(0 0 0 / 80%);
    margin: 8px 26px 0;
    display: block;
  }

  .home-detail-title-h3-mob {
    display: none;
  }

  .homepl-detail-second-title-container {
    margin: 0 auto;
    max-width: 940px;
  }

  .homepl-detail-second-title-container-h2 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin: 24px 26px 32px;
  }

  .homepl-detail-card-container:hover {
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 10%);
  }

  .homepl-detail-card-container {
    display: flexbox;
    display: flex;
    font-size: 1em;
    padding: 24px 16px;
    margin-right: 24px;
  }

  .homepl-detail-card {
    display: flexbox;
    display: flex;
    margin: auto;
    max-width: 940px;
    padding-right: 0;
    padding-left: 26px;
  }

  .homepl-detail-card:first-child {
    margin-left: 26px;
  }

  .homepl-detail-card-icon svg {
    display: block;
    width: auto;
  }

  .home-text-card-h3 {
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    color: rgb(0 0 0 / 80%);
  }

  .home-text-card-p {
    font-size: 14px;
    line-height: 1.29;
    text-decoration: none;
    color: rgb(0 0 0 / 80%);
    margin-top: 8px;
    margin-bottom: 0;
  }

  .payments-card-link-second-pl {
    display: flexbox;
    display: flex;
  }

  .homepl-banner {
    margin: 48px auto;
    max-width: 940px;
  }

  .homepl-banner-container {
    display: flexbox;
    display: flex;
    flex-direction: row;
    max-width: 940px;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 12%);
    background-color: #fff;
    margin-top: 48px;
    margin-right: 26px;
    margin-left: 26px;
  }

  .homepl-banner-text {
    padding: 38px 0 38px 72px;
  }

  .homepl-banner-p {
    font-size: 14px;
    color: rgb(0 0 0 / 80%);
    background-color: #fff;
    margin: 0;
  }

  .homepl-banner-h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.25;
    color: rgb(0 0 0 / 80%);
    border-radius: 6px;
    background-color: #fff;
    padding: 8px 0 28px;
    margin: 0;
  }

  .homepl-banner-h1-shopify {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.25;
    color: rgb(0 0 0 / 80%);
    border-radius: 6px;
    background-color: #fff;
    padding-bottom: 44px;
    padding-top: 0;
    margin: 0;
  }

  .homepl-banner-image {
    padding: 39px 0 35px 32px;
    max-width: 500px;
  }

  .homepl-banner-image img {
    max-width: 80%;
  }

  .homepl-banner-card-link:hover {
    text-decoration: none;
  }

  .homepl-banner-button {
    padding: 0 38px;
    font-size: 0.875em;
    line-height: 2.7142857142857144;
    background: $andes-accent-color;
    border-radius: 4px;
    border: 0;
    text-align: center;
  }

  .homepl-banner-button:hover {
    text-decoration: none;
    cursor: pointer;
    background-color: #007eb5;
    transition: background-color 0.2s ease-in;
  }

  .titlepl-banner-button {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }
}

@media (width >= 1024px) {
  .layout {
    min-height: 100vh;
  }

  .collapsed-anchored-menu {
    margin-right: 0;
  }

  .layout__content {
    padding: 0;
  }

  .layout__container {
    max-width: 50.875em;
    margin: 0 auto;
    margin-top: 60px;
  }

  .layout__container > hr {
    height: 1px;
    opacity: 0.68;
    color: #ddd;
    border: solid 0.5px;
    margin-top: 60px;
    margin-bottom: 40px;
  }

  .layout__dimmer {
    display: none !important;
  }

  .ui-button {
    display: inline-block;
    cursor: pointer;
  }

  .ui-button[disabled] {
    cursor: default;
  }

  .ui-button + .ui-button {
    margin-left: 1em;
  }

  .navbar {
    padding-right: 0;
  }

  .navbar__logo {
    width: 9.75em;
    height: 2.5em;
    margin-right: 5.5em;
  }

  .navbar .layout__content {
    padding-left: 0;
    width: 100%;
    height: 100%;
  }

  .navbar__container {
    margin-left: 0;
    margin-right: 50px;
    max-width: none;
    padding: 0;
    margin: 0 auto;
  }

  .navbar__logo-iso {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.75em;
    height: 2em;
    width: auto;
  }

  .navbar__logo-text {
    display: inline-block;
    vertical-align: middle;
    color: #000;
    font-size: 1em;
    margin: 0.5em 0;
  }

  .navbar__menu {
    display: inline-flexbox;
    display: inline-flex;
    position: initial;
    right: 0;
    margin-left: 0;
  }

  .navbar__controls {
    margin-right: 2.5em;
  }

  .navbar__navicon,
  .navbar__search-btn,
  .navbar__user::after,
  .navbar__search-reset {
    display: none;
  }

  .navbar__container {
    position: relative;
  }

  .navbar__search-form {
    left: 0;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    -ms-flex-align: center;
    align-items: center;
    width: 12.5em;
    height: 100%;
    max-width: 50.875em;
    transition: width 400ms ease-out;
    animation: fade-in 200ms linear;
  }

  .navbar-search-active .navbar__search-form {
    width: 100%;
  }

  .form-search-icon {
    position: absolute;
    top: 1.625em;
    z-index: 2;
    display: block;
    margin-left: 1em;
  }

  .navbar__search-form input {
    position: inherit;
    width: 100%;
    height: 2.1875em;
    margin-top: 1em;
    padding: 0 1.25em 0 2.6875em;
    font-size: 1em;
    line-height: 2.1875em;
    border-radius: 0.25em;
    background-color: #fff;
    border: solid 0.0625em #dbe1ec;
  }

  .navbar-search-active .navbar__search-input {
    left: auto;
    z-index: 1;
    height: 2.1875em;
  }

  .navbar__search-reset {
    height: 100%;
    padding: 0;
  }

  .navbar__search-reset::before,
  .navbar__search-reset::after {
    margin-top: -0.5em;
  }

  .navbar-search-active .navbar__search-reset {
    display: block;
    right: 0;
  }

  .navbar__user-logged-btn img {
    width: 2em;
    height: 2em;
  }

  .navbar__user-menu {
    right: 20px;
    margin-top: 0;
  }

  .navbar__menu-item {
    display: inline-block;
    transform: scale(1);
    opacity: 1;
    transition: opacity 200ms;
    transition-delay: 180ms;
  }

  .navbar__menu-item:nth-child(2) {
    transition-delay: 240ms;
  }

  .navbar__menu-item:nth-child(3) {
    transition-delay: 300ms;
  }

  .navbar-search-active .navbar__reference {
    opacity: 0;
    transition-delay: 0;
  }

  .navbar-search-active .navbar__support {
    opacity: 0;
    transition-delay: 0;
  }

  .navbar__controls {
    display: inline;
    -ms-flex-positive: initial;
    flex-grow: initial;
    margin-right: 0;
  }

  .navbar__user {
    top: 0;
    right: 0.5em;
  }

  .docs-not-found__container {
    .layout__main {
      margin-right: 0;
    }
  }
}

@media (width >= 1024px) and (width >= 1280px) {
  .navbar__logo {
    position: relative;
  }

  .navbar__menu {
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-left: 0;
    text-align: left;
    display: inline-flexbox;
    display: inline-flex;
  }

  .navbar__search-form {
    width: 18.75em;
  }

  .navbar__user {
    right: 1em;
  }
}

@media (width >= 1024px) and (width >= 1610px) {
  .navbar__user-nickname {
    display: inline-block;
    vertical-align: middle;
    color: #666;
    margin-right: 0.625em;
  }

  .user-menu-chevron {
    position: relative;
    top: -0.25em;
  }
}

@media (width >= 1024px) {
  .navbar__vertical-separator {
    height: 23px;
    border-right: solid 1px #d6d6d6;
    margin-right: 31px;
  }

  .navbar__vertical-line {
    width: 1;
    size: auto;
  }

  .navbar__user-nickname {
    display: inline-block;
    font-size: 14px;
    line-height: 1.29;
    color: #000;
  }

  .iconsgrid {
    display: flexbox;
    display: flex;
    margin-left: 26px;
    margin-top: 48px;
  }

  .iconsgrid a {
    display: block;
    width: 64px;
    height: 64px;
    margin-bottom: 20px;
    margin-right: 19px;
    background: #fff;
    overflow: hidden;
    border-radius: 0.25em;
    font-size: 0.875em;
    box-shadow: 0.07143em 0.14286em 0.21429em 0 rgb(0 0 0 / 10%);
  }

  .navbar__login-button {
    margin-right: 23px;
    margin-left: 34px;
  }

  .sidebar {
    left: 0 !important;
    transform: translate3d(0, 0, 0) !important;
    margin-top: 4.0625em;
    height: calc(100% - 4.0625em);
    overflow: auto;
    overflow-x: hidden;
    border-right: 0;
  }

  .sidebar__menu {
    position: relative;
    width: 101%;
    height: auto;
    padding-top: 12px;
    overflow: auto;
    border: 0;
  }

  .sidebar::-webkit-scrollbar-track,
  .sidebar::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  .sidebar::-webkit-scrollbar-thumb {
    background: rgb(0 0 0 / 16%);
    border-radius: 2px;
  }
}

@media (width >= 1024px) {
  .searh-results.is-active {
    background: rgb(238 238 238 / 90%);
    z-index: 70;
    padding-left: 16.875em;
  }

  .search-results__box {
    max-width: 50.875em;
    margin: 1.5em auto;
  }

  .c-404 {
    margin: 11.25em auto 6.25em;
  }

  .tooltip-content {
    left: -75px;
  }
}

@media (width >= 1024px) and (width >= 1024px) and (width <= 1093px) {
  .like-widget {
    padding-right: 0 !important;
  }

  .satisfaction-button-container {
    margin-left: 10px !important;
  }

  #container-no {
    margin-left: 9px !important;
  }
}

@media (width >= 1024px) {
  .like-widget {
    background: #eff0f3;
    box-shadow: none;
    padding: 32px;
    display: none;
    margin-bottom: 0 !important;
  }

  .title__like {
    font-size: 18px;
    line-height: 1.56;
    color: #04204c;
  }

  .satisfaction-container {
    height: 40px;
  }

  .satisfaction-title-container {
    width: auto;
    float: left;
    margin-top: 8px !important;
  }

  .andes-button--small {
    display: inline-table !important;
  }

  .satisfaction-title-container.h2 {
    margin-top: 7px;
    font-size: 18px;
  }

  .satisfaction-button-container {
    float: left;
    margin-left: 40px;
    margin-top: 0;
  }

  .satisfaction-answer {
    font-size: 16px;
    margin-top: 24px;
    float: left;
    display: none;
  }

  .satisfaction-answer.p {
    float: left;
  }

  .hidden {
    display: none;
  }

  .layout__content__home {
    padding: 0;
    margin-left: -60px;
  }

  .layout__content-sdk {
    padding: em(0) em(0) em(0);
  }

  .layout__container__home {
    margin-top: 0;
    margin: 0;
  }

  .layout__container__home > hr {
    height: 1px;
    opacity: 0.68;
    color: #ddd;
    border: solid 0.5px;
    margin-top: 60px;
    margin-bottom: 40px;
  }
}

.andes-spinner__mask {
  background-color: $light-gray-2 !important;
}

//Landing SDK styles

// Shopify Banner

.homepl-banner-image {
  padding: 43px 20px 35px 23px;
  max-width: 300px;
}

.homepl-banner-image-shopify {
  display: flex;
  padding: 26px 0 0 15px;
  max-width: 230px;
  margin-right: 70px;

  img {
    width: 100%;
    height: 100%;
  }
}

@media (width >= 1024px) {
  .right-sidebar {
    display: flex;
    flex-direction: column;
    position: sticky;
    right: 10px;
    top: 120px;
    align-items: end;
    float: right;
    max-width: 250px;
    width: 100%;
    height: 100%;

    &-reference {
      bottom: 0;
      display: flex;
      flex-direction: column;
      position: sticky;
      align-items: end;
    }

    .feedback-v2-container.feedback-v2-container--aside {
      padding: 0 !important;
    }

    .feedback-thumbs {
      margin-bottom: 24px;
    }
  }
}

@media (width >= 500px) and (width <= 1024px) {
  .right-sidebar {
    display: flex;
    padding-top: 85px;
    flex-direction: column;
    margin: 0 24px;

    .feedback-thumbs {
      margin-bottom: 24px;
    }
  }
}

@media (width <= 500px) {
  .right-sidebar {
    display: flex;
    padding-top: 24px;
    flex-direction: column;

    .feedback-thumbs {
      margin-bottom: 24px;
    }
  }
}

@media (width >= 768px) {
  // frontend/assets/stylesheets/components/home/home-plugins/_home-plugins.medium.scss

  .homepl-banner-image-shopify {
    max-width: unset;
  }

  .homepl-banner-card-link {
    display: contents;
  }
}

// END Shopify banner

@import "../../../components/landings/Home/styles.mobile";
@import "../../../components/landings/Home/styles.mobile";
@import "../../../components/landings/OnlinePayments/styles.mobile";
@import "../../../components/landings/InPersonPayments/styles.mobile";
@import "../../../components/landings/Plugins/styles.mobile";
@import "../../../components/landings/Sdks/styles.mobile";
@import "../../../components/landings/Overview/styles.mobile";
@import "../../../components/FTUModal/styles.mobile";
@import "../../../components/Documentation/components/NextStepCard/styles.mobile";

@media (width >= 1024px) {
  @import "../../../components/landings/Home/styles.desktop";
  @import "../../../components/landings/OnlinePayments/styles.desktop";
  @import "../../../components/landings/InPersonPayments/styles.desktop";
  @import "../../../components/landings/Plugins/styles.desktop";
  @import "../../../components/landings/Sdks/styles.desktop";
  @import "../../../components/landings/Overview/styles.desktop";
  @import "../../../components/FTUModal/styles.desktop";
  @import "../../../components/Documentation/components/NextStepCard/styles.desktop";
}

@import "~dx-devsite-markdown/src/dx-devsite-markdown/index";
@import "../../../components/SearchBar/index";

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #fff;
  box-shadow: 0 1px 2px rgb(0 0 0 / 12%);
  border-radius: 6px;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 16px;
}

code * {
  font-family: "Roboto Mono", monospace !important;
}

.docspage_wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
