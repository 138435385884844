@import "./components/Backend/styles.desktop";
@import "./components/Frontend/styles.desktop";
@import "./components/Requirements/styles.desktop";
@import "./components/SdksCard/styles.desktop";
@import "./components/LastVersion/styles.desktop";

.sdks-layout-container {
  padding: 65px 0 0 90px;
  margin-right: 314px;
}

.sdks-container {
  max-width: 960px;
  padding: 0;
  margin: 0 auto;
}

.sdks-title-ul {
  color: $black-2;
  margin-left: 32px;
}

.sdks-content {
  border-bottom: solid 0.5px rgb(0 0 0 / 10%);
  padding: 0 0 70px;

  &__last {
    margin-top: 32px;
  }
}

// SDKs page custom styles

.sdks-h2 {
  font-size: 1.5em;
  line-height: 1.17;
  margin: 2.33333em 0 1.33333em;
}

.landings-h1 {
  line-height: 1.15;
  margin-top: 65px;
  margin-bottom: 20px;
}

.next-step {
  margin-top: 2.33333em;
}

.landing-sidebar-anchor {
  overflow: scroll;
}
