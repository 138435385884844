.anchored-menu {
  width: 250px;
  visibility: visible;
  z-index: 10;
  height: 100%;
  display: block;
  margin-bottom: 15px;

  /* Chrome/Safari/Opera */

  /* Firefox */

  /* Internet Explorer/Edge */
  user-select: none;
}

.anchored-menu-box {
  width: 250px;
  right: 0;
  border: 1px solid $black-5;
  box-sizing: border-box;
  border-radius: 6px;
  border-color: $white;
  background-color: $white;
}

.anchored-menu p:first-child {
  width: 168px;
  height: 14px;
  margin: 16px 1px 18px 16px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  color: rgb(0 0 0 / 80%);
}

.anchored-menu ul {
  margin: 0;
  list-style-type: none;
}

.pl {
  padding-left: 16px;
}

.anchored-menu-list {
  content: "";
  display: block;
  top: 7px;
  left: 0;
  bottom: 7px;
  width: 0;
  background: rgb(153 153 153 / 50%);
  margin-bottom: 0;
}

.anchored-menu li {
  width: 240px;
  list-style: none;
  list-style-type: none;
  margin-bottom: 12px;
  line-height: 1.1;
}

.anchored-menu a:hover {
  color: $andes-accent-color;
}

.anchored-menu-link-active a {
  font-weight: 600;
  color: $andes-accent-color;
}

.anchor-list {
  margin: 0;
  padding-left: 0;
}

li.anchor-list-item {
  position: relative;
  padding-left: 16px;
}

a.anchor-link {
  height: 100%;
  text-align: justify;
  color: rgb(0 0 0 / 45%);
  font-size: 12px;
}

.is-collapsible {
  max-height: 1000px;
  overflow: hidden;
  transition: 300ms ease-in-out;
}

.is-collapsed {
  max-height: 0;
}

.is-position-fixed {
  position: fixed !important;
  top: 0;
}

h2::before,
h3::before,
.anchor-link::before {
  content: " ";
  display: inline-block;
  height: inherit;
  left: 0;
  position: absolute;
  width: 2px;
}

.is-active-link {
  color: $andes-accent-color !important;

  &::before {
    background-color: $andes-accent-color;
    width: 4px;
    border-bottom-right-radius: 3px;
    min-height: 24px;
    border-top-right-radius: 3px;
  }
}

.anchor-mobile {
  display: none;
}

li.anchor-list-item:last-child {
  padding-bottom: 8px;
}
