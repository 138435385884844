.home-static-banner-content {
  flex-direction: row !important;
  padding: 0 !important;
  max-width: 866px !important;

  .home-static-banner-text__title {
    text-align: left;
  }

  .home-static-banner-text__detail {
    text-align: left;
  }

  .home-static-banner-image {
    padding-top: 0 !important;
  }

  .home-static-banner-button {
    .andes-button--loud,
    .andes-button--loud:link,
    .andes-button--loud:visited,
    .andes-button--loud:focus {
      width: auto !important;
    }
  }
}
