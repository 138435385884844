.main-page-container {
  &--with-sidebar {
    margin-left: 270px;
    background-color: $light-gray-1;
    padding-bottom: 20px;
  }
}

.sidebar-mobile-navbar {
  display: none;
}

.slideout-panel {
  overflow-y: auto;
  max-height: 100vh;
}

.sidebar-desktop-navbar {
  position: relative;
  padding: 44px 16px 0;
  display: block;
  z-index: 1030;

  input {
    width: 100%;
    height: 48px;
    padding-left: 40px;
    font-size: 16px;
    font-weight: 400;
    color: rgb(0 0 0 / 55%);
    line-height: 20px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    background-color: rgb(245 245 245 / 100%);
  }
}

.sidebar-mobile-navbar,
.sidebar-container {
  width: 270px;
}

.sidebar-container {
  display: flex;
  margin-left: 0;
  background-color: $pale-gray;
  z-index: 1030;
  transform: translateY(0) !important;
  position: sticky;
  height: calc(100vh - 65px);
  top: 65px;
  left: 0;
  overflow: hidden;
  padding-top: 0;
  float: left;

  .slideout-open .slideout-dimmer {
    visibility: hidden;
    opacity: 1;
    z-index: 15;
  }

  .item-container {
    height: auto;

    .title-lvl-0 {
      margin-bottom: 16px;
    }

    &.item-container-lvl--1,
    &.item-container-lvl--2 {
      margin-top: 16px;
    }

    &-lvl-0 {
      margin-top: 24px;
    }

    &-lvl-0 p.title {
      margin: 0;
    }

    .collapse-image {
      padding: 0;
    }

    &.api-ref-container {
      &.item-container-lvl--1 {
        margin-top: 24px !important;
      }
    }
  }

  .ul-container {
    padding-left: 0;
    margin-top: 0;

    .childs {
      & > div {
        margin-top: 16px;
      }

      a {
        display: flex;
      }
    }
  }
}

.nav-container {
  width: 100%;
  flex-direction: column;
}

.inner-content--empty {
  position: sticky;
  left: 270px;
  right: 0;
  height: calc(100vh - 65px);
  z-index: 10;
}
