@import "~@andes/common/index";
@import "~@andes/thumbnail/index";
@import "~@andes/form/index";
@import "~@andes/textfield/index";
@import "~@andes/modal/index";
@import "~@andes/button/index";
@import "~@andes/carousel-snapped/index";
@import 'dx-feedback-utils/src/CSAT/styles.scss';
@import 'dx-feedback-utils/src/HomologCSAT/styles.scss';

// Para mobile
@import "../components/layout/styles.mobile";
@import "../components/AnchoredMenu/styles.mobile";
@import "../components/AnchoredLanding/styles.mobile";

@import "~@andes/floating-menu/base";
@import "~@andes/list/index";
@import "~@andes/checkbox/index";
@import "~@andes/floating-menu/lib/styles/multiple";
@import "~@andes/tag/base";
@import "~@andes/tag/lib/tagCollapsed";
@import "~@andes/progress-indicator-circular/index";

@media (width <= 1024px) {
  // Set Andes bundle
  $andes-bundle: "mobile";
}

@media (width >= 768px) {
  @import "../components/layout/styles.desktop";
  @import "../components/AnchoredMenu/styles.desktop";

  // Set Andes bundle
  $andes-bundle: "mobile";
}

.u-hide {
  display: none;
}

.nav-icon-help::before {
  content: none !important;
}

.layout-container {
  max-width: 100%;
}

// sass-lint:disable id-name-format no-ids
.layout-container #root-app {
  padding: 32px 0 0;
}

.andes-technical-error__container {
  min-height: 100vh;
}

@import "dx-feedback-utils/src/FeedbackThumbs/styles.scss";