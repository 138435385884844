.next-step-card-text {
  display: block;
}

.next-step-card-chevron {
  margin-right: 5px;

  svg {
    path {
      fill: $andes-accent-color;
    }
  }
}

.next-step-card-title-container {
  padding-left: 0;
}

.next-step-card-container {
  margin-left: 24px;
}

.next-step-card {
  width: 50%;

  & ~ .next-step-card {
    border-left: 2px solid $light-gray-10;
    height: 112px;
  }
}
