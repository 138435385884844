.sdks-frontend-container {
  position: relative;
}

.sdks-select-version-dropdown {
  margin-left: 8px;
}

.sdks-select-version-li {
  width: 240px;
}

.sdks-select-version {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;

  .andes-dropdown__popover {
    width: 240px !important;
  }

  .andes-dropdown__trigger {
    font-size: 16px !important;
  }

  .andes-list__item {
    display: flex;
    align-items: center;
  }
}

.sdks-pill {
  max-height: 24px;
}

.sdk-install {
  margin-top: 42px;
}

.sdks-beta-pill {
  margin-left: 12px;
}

.no-overflow {
  overflow: hidden !important;
}

.tab__sel {
  padding: 2px 24px !important;
}

.sdks-content-notification-deprecated {
  margin-top: 16px;

  &__title {
    display: flex;
    align-items: center;

    .sdks-v1-deprecated__title {
      margin: 0 0 0 16px;
    }
  }
}

.overview-link {
  cursor: pointer;
}

.sdk-fraud-message {
  margin-top: 48px;
}
