.ftu-modal {
  padding: 24px;

  .andes-modal-dialog__content {
    padding: 0;
  }
}

.ftu-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ftu-modal__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 22px;
}

.ftu-modal__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  color: rgb(0 0 0 / 80%);
}

.ftu-modal__p {
  font-size: 16px;
  margin-top: 8px;
  text-align: center;
  line-height: 1.25;
  color: rgb(0 0 0 / 80%);
}

.ftu-modal__button-container {
  margin-top: 28px;
}

.ftu-modal-component {
  .andes-modal-dialog__button-close::before {
    background-color: $andes-accent-color !important;
  }
}
